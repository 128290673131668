import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from 'react-router-dom';

import { getBankAccountsListFetch } from '../../../../redux/withdrawal/reducer';

const useHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);

  const isMobileWithTablet = useMediaQuery({ query: "(max-width: 1024px)" });

  const [isMenu, setIsMenu] = useState(false);
  const [isKYC, setIsKYC] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isAccountUnderVerify, setIsAccountUnderVerify] = useState(false);
  const [isUploadKYC, setIsUploadKYC] = useState(false);
  const [isLoginAfter, setIsLoginAfter] = useState(isLoggedIn ? true : false);
  const [isAccountReject, setIsAccountReject] = useState(false);
  const [isAccountApproved, setIsAccountApproved] = useState(false);

  useEffect(() => {
    if (isLoggedIn === true) {
      setIsLoginAfter(true);
    }
  }, [isLoggedIn]);

  const onToggleMenu = () => {
    setIsMenu((state) => !state);
  };

  const onOpenLoginModal = () => {
    setIsOpenModal(true);
  };

  const onCloseModal = () => {
    setIsOpenModal(false);
    setIsAccountUnderVerify(false);
    setIsVerify(false);
  };

  const onOpenVerify = () => {
    setIsVerify(true);
  };

  const onOpenKYC = () => {
    setIsKYC(true);
    setIsAccountReject(false);
  };

  const onOpenUploadKYC = () => {
    setIsUploadKYC(true);
  };

  const onOpenAccountUnderVerify = () => {
    setIsLoginAfter(true);
    setIsOpenModal(false);
  };

  const onOkayAccountUnderVerify = () => {
    setIsOpenModal(true);
    setIsAccountUnderVerify(true);
  };

  const goToBackLogin = () => {
    setIsVerify(false);
  };

  const goToBackVerificationCode = () => {
    setIsUploadKYC(false);
  };
  
  const onOpenAccountApproved = () => {
    setIsOpenModal(true)
    setIsAccountApproved(true);
    setIsAccountReject(false);
  };

  const onOkayAccountApproved = () => {
    setIsOpenModal(true);
    setIsOpenModal(false);
  };

  const onOkayAccountRejected = () => {
    setIsOpenModal(true);
    setIsAccountReject(true);
  };

  const VerifyUserOtpCallback = (verifyOtpResponse) => {
    if (verifyOtpResponse.responseStatus === true) {
      if (isOpenModal === true) {
        dispatch(
          getBankAccountsListFetch({
            payload: { limit: 10, offset: 0 },
          })
        );
        setIsVerify(false);
        setIsOpenModal(false);
        navigate("/");
      }
    }
  };

  return {
    isMobileWithTablet,
    isMenu,
    setIsMenu,
    onToggleMenu,
    isOpenModal,
    onOpenLoginModal,
    onCloseModal,
    isVerify,
    onOpenVerify,
    isUploadKYC,
    onOpenUploadKYC,
    isAccountUnderVerify,
    onOpenAccountUnderVerify,
    onOkayAccountUnderVerify,
    goToBackLogin,
    goToBackVerificationCode,
    isLoginAfter,
    isKYC,
    onOpenKYC,
    isAccountReject,
    isAccountApproved,
    onOpenAccountApproved,
    onOkayAccountApproved,
    VerifyUserOtpCallback,
    onOkayAccountRejected,
  };
};

export default useHeader;
