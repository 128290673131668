import React from "react";
import { useSelector } from "react-redux";
// component
import Flex from "../../../../../components/flex";
import Text from "../../../../../components/text";
import Image from "../../../../../components/image";
import LabelText from "../../../../../components/labelText";
// images
import PurchaseFailedImg from "../../../../../assets/img/purchase-failed-img.png";
// utils
import { truncate } from '../../../../../utils/util';

const PurchaseFailed = (props) => {

  const { walletAddress, buyNetworkFee, buyReceiveData, buyFiatData } = useSelector(
    (state) => state?.depositPersistReducer
  );

  const { exchangeRateBuy, markUp, walletInfo } = useSelector((state) => state?.userReducer);

  const calculateCrypto = () => {
    return truncate(
      (buyNetworkFee?.total) /
      parseFloat(Number(exchangeRateBuy) + Number(exchangeRateBuy * parseFloat(markUp)))
    );
  };

  return (
      <Flex flexColumn justifyContent="between" className="p-4 height-100">
        <Flex flexColumn>
          <Flex flexColumn alignItems="center" className="mb-20">
            <Image src={PurchaseFailedImg} alt="Purchase complete" />
          </Flex>
          <Text
            type="H3"
            text="Purchase failed"
            className="text-center mb-10"
          />
          <Text
            type="PARAGRAPH"
            className="font-size-14 mb-30"
            text="We didn’t receive the amount to our bank. Please retry payment."
          />
          <LabelText
            lists={[
              {
                label: "Order ID",
                text: "#1394801351",
              },
              {
                label: "Total Sent",
                text: `${buyNetworkFee.price} ${buyFiatData.value}`,
              },
              {
                label: "Fee",
                text: `${buyNetworkFee?.payonrampFee} ${buyFiatData.value}`,
              },
              {
                label: "Network Fee",
                text: `${truncate(buyNetworkFee?.network)} ${buyFiatData.value}`,
              },
              {
                label: buyReceiveData?.token ? `Received` : "Received",
                text: `${calculateCrypto()} ${buyReceiveData.token}`,
              },
              {
                label: "Wallet ID",
                text: `${walletInfo || walletAddress?.walletAddress}`,
              },
            ]}
          />
        </Flex>
        <Flex flexColumn>
          <Flex className="mb-10" flexColumn>
            {/* <Button type="button" text="Retry Payment" onClick={handleClick} /> */}
          </Flex>
        </Flex>
      </Flex>
  );
};

export default PurchaseFailed;
