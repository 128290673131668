import { put, takeEvery, call } from "redux-saga/effects";
import { STATUS_CODE } from "../../utils/constants";
import { httpGet, httpPost } from "../../utils/axiosConnect";
import API from "../../utils/api";
import { toasts } from "../../utils/toast";
import {
  saveOTPReferenceDetails,
  savePhoneNumber,
} from "./persistReducer";

import { saveUserLoginDetails } from "../user/persistReducer";

import { saveVoucherDetails } from "../user/reducers";

import {
  initiatTransactionFailure,
  initiatTransactionSuccess,
  getPhoneNumberOtpFailure,
  getPhoneNumberOtpSuccess,
  verifyOtpFailure,
  verifyOtpSuccess,
  addBankAccountsFailure,
  addBankAccountsSuccess,
  getBankAccountsListFailure,
  getBankAccountsListSuccess,
  verifyCryptoDepositFailure,
  verifyCryptoDepositSuccess,
  verifyBankAccountsFailure,
  verifyBankAccountsSuccess,
  addTicketFailure,
  addTicketSuccess,
  fetchTicketsSuccess,
  fetchTicketsFailure,
} from "./reducer";

function* initiateTransactionSaga({ payload }) {
  try {
    const response = yield httpPost(
      API.CPG.USER.INITIATE_USER_TRANSACTION,
      payload?.values,
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(initiatTransactionSuccess(response.data.data));
      if (response?.data?.data?.accessToken) {
        localStorage.setItem('accessToken', response?.data?.data?.accessToken);
        localStorage.setItem('refreshToken', response?.data?.data?.refreshToken);
        localStorage.setItem('expiry', response?.data?.data?.expiry);
      }
      payload?.callback({
        status: response?.status,
        data: { ...response?.data?.data, ...payload?.values },
        message: response?.data?.message,
        applicationId: payload?.values.applicationId,
      });
    } else if (
      response?.status ===
      (STATUS_CODE?.unAuthorized ||
        STATUS_CODE.forbidden ||
        STATUS_CODE.badRequest)
    ) {
      yield put(initiatTransactionFailure());
      payload?.callback({
        status: response?.status,
        message: response?.data?.message,
        applicationId: payload?.values.applicationId,
      });
      toasts.error(response?.data?.message);
    } else {
      yield put(initiatTransactionFailure());
      payload?.callback({
        status: response?.status,
        message: response?.data?.message,
        applicationId: payload?.values.applicationId,
      });
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(initiatTransactionFailure());
    payload?.callback({
      status: error?.status,
      message: error?.data?.message,
      applicationId: payload?.values.applicationId,
    });
  }
}

function* getPhoneNumberOtpSaga({ payload }) {
  try {
    const response = yield httpPost(API.CPG.USER.LOGIN, payload.values);
    if (response?.status === STATUS_CODE?.successful) {
      yield put(getPhoneNumberOtpSuccess(response.data.data.otpReferenceKey));
      yield put(
        saveOTPReferenceDetails({
          otpReferenceKey: response.data.data.otpReferenceKey,
        })
      );
      yield put(
        savePhoneNumber({
          mobileNumber: payload?.values?.phonenumber,
        })
      );
      // payload.otpCallback(true);
    } else if (
      response?.status === STATUS_CODE?.unAuthorized ||
      response?.status === STATUS_CODE.forbidden ||
      response?.status === STATUS_CODE.badRequest
    ) {
      yield put(getPhoneNumberOtpFailure());
      // payload.otpCallback(false);
      toasts.error(response?.data?.message);
    } else {
      yield put(getPhoneNumberOtpFailure());
      // payload.otpCallback(false);
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(getPhoneNumberOtpFailure());
    // payload.otpCallback(false);
  }
}

function* verifyOtpSaga({ payload }) {
  try {
    const response = yield httpPost(
      API.CPG.USER.VERIFY_OTP,
      payload?.values
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(verifyOtpSuccess(response.data.data));
      yield put(saveUserLoginDetails());
      localStorage.setItem('accessToken', response.data.data.accessToken);
      localStorage.setItem('refreshToken', response.data.data.refreshToken);
      localStorage.setItem('expiry', response.data.data?.expiry);
      payload.callback({
        responseStatus: true,
        existingUser: response.data.data.existingUser,
        kycApproved: response.data.data.kycApproved,
        kycStatus: response.data.data?.kycStatus || null,
      });
    } else if (
      response?.status === STATUS_CODE?.unAuthorized ||
      response?.status === STATUS_CODE.forbidden ||
      response?.status === STATUS_CODE.badRequest
    ) {
      yield put(verifyOtpFailure());
      toasts.error(response?.data?.message);
      payload.callback({ responseStatus: false, existingUser: null });
    } else {
      yield put(verifyOtpFailure());
      toasts.error(response?.data?.message);
      payload.callback({ responseStatus: false, existingUser: null });
    }
  } catch (error) {
    yield put(verifyOtpFailure());
    payload.callback({ responseStatus: false, existingUser: null });
  }
}

function* addBankAccountSaga({ payload }) {
  try {
    const response = yield httpPost(
      `${API.USER.ADD_BANK_ACCOUNT}`,
      payload?.values
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(addBankAccountsSuccess(response?.data));
      payload.callback();
    } else if (
      response?.status ===
      (STATUS_CODE?.unAuthorized ||
        STATUS_CODE.forbidden ||
        STATUS_CODE.badRequest)
    ) {
      yield put(addBankAccountsFailure());
      toasts.error(response?.data?.message);
    } else {
      yield put(addBankAccountsFailure());
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(addBankAccountsFailure());
  }
}

function* bankAccountListSaga({ payload }) {
  try {
    const response = yield httpGet(
      `${API.USER.GET_BANK_DETAILS}`,
      payload?.payload
    );

    if (response?.status === STATUS_CODE?.successful) {
      yield put(getBankAccountsListSuccess(response?.data));
    } else if (
      response?.status ===
      (STATUS_CODE?.unAuthorized ||
        STATUS_CODE.forbidden ||
        STATUS_CODE.badRequest)
    ) {
      yield put(getBankAccountsListFailure());
      toasts.error(response?.data?.message);
    } else {
      yield put(getBankAccountsListFailure());
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(getBankAccountsListFailure());
  }
}

function* verifyCryptoDepositSaga({ payload }) {
  try {
    const response = yield httpPost(
      API.TTRANSACTIONS.CRYPTO_DEPOSIT,
      payload.data
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(verifyCryptoDepositSuccess(payload?.transactionDetails));
      payload?.callback();
      // yield put(
      //   upadteTransactionDetails({
      //     ...payload?.transactionDetails,
      //     transactionTime: new Date(),
      //   })
      // );
      // payload?.gotoPleaseWait();
    } else if (
      response?.status ===
      (STATUS_CODE?.unAuthorized ||
        STATUS_CODE.forbidden ||
        STATUS_CODE.badRequest)
    ) {
      yield put(verifyCryptoDepositFailure());
      // yield put(updatCryptoTransferRequest(false));
      // payload?.showUtrError(response?.data?.message);
      toasts.error(response?.data?.message);
    } else {
      yield put(verifyCryptoDepositFailure());
      // yield put(updatCryptoTransferRequest(false));
      // payload?.showUtrError(response?.data?.message);
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(verifyCryptoDepositFailure());
    // yield put(updatCryptoTransferRequest(false));
    // payload?.showUtrError(error?.message);
  }
}

function* verifyBankAccountSaga({ payload }) {
  try {
    const response = yield httpPost(
      `${API.USER.CONFIRM_BANK}`,
      { bankId: payload?.bankId }
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(verifyBankAccountsSuccess(response?.data));
      // payload.callback();
    } else if (
      response?.status ===
      (STATUS_CODE?.unAuthorized ||
        STATUS_CODE.forbidden ||
        STATUS_CODE.badRequest)
    ) {
      yield put(verifyBankAccountsFailure());
      toasts.error(response?.data?.message);
    } else {
      yield put(verifyBankAccountsFailure());
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(verifyBankAccountsFailure());
  }
}

function* addTicketSaga({ payload }) {
  try {
    const response = yield httpPost(
      `${API.USER.ADD_TICKET}`,
      payload?.formData
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(addTicketSuccess());
      yield call(fetchTicketsSaga);
    } else if (
      response?.status ===
      (STATUS_CODE?.unAuthorized ||
        STATUS_CODE.forbidden ||
        STATUS_CODE.badRequest)
    ) {
      yield put(addTicketFailure());
      toasts.error(response?.data?.message);
    } else {
      yield put(addTicketFailure());
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(addTicketFailure());
  }
}

function* fetchTicketsSaga() {
  try {
    const response = yield httpGet(
      `${API.USER.FETCH_TICKET}`,
      {}
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(fetchTicketsSuccess(response?.data?.data));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(fetchTicketsFailure());
    } else {
      yield put(fetchTicketsFailure());
    }
  } catch (error) {
    yield put(fetchTicketsFailure());
  }
}

function* validateVoucherSaga({ payload }) {
  try {
    const response = yield httpPost(
      `${API.CPG.USER.VALIDATE_VOUCHER}`,
      payload?.values
    );

    if (response?.status === STATUS_CODE?.successful) {
      payload.callback(response?.data?.data);
      yield put(saveVoucherDetails(payload?.values.voucher));
    } else {
      payload.callback(false);
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    payload.callback(false);
  }
}

function* withdrawalSaga() {
  yield takeEvery(
    "withdrawalReducer/initiateTransactionFetch",
    initiateTransactionSaga
  );
  yield takeEvery(
    "withdrawalReducer/getPhoneNumberOtpFetch",
    getPhoneNumberOtpSaga
  );
  yield takeEvery("withdrawalReducer/verifyOtpFetch", verifyOtpSaga);
  yield takeEvery("withdrawalReducer/addBankAccountsFetch", addBankAccountSaga);
  yield takeEvery(
    "withdrawalReducer/getBankAccountsListFetch",
    bankAccountListSaga
  );
  yield takeEvery(
    "withdrawalReducer/verifyCryptoDepositFetch",
    verifyCryptoDepositSaga
  );
  yield takeEvery(
    "withdrawalReducer/verifyBankAccountsFetch",
    verifyBankAccountSaga
  );
  yield takeEvery("withdrawalReducer/addTicket", addTicketSaga);
  yield takeEvery("withdrawalReducer/fetchTickets", fetchTicketsSaga);
  yield takeEvery("withdrawalReducer/validateVoucherFetch", validateVoucherSaga);
}

export default withdrawalSaga;
