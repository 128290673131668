import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "../../../../components/button";
import Text from "../../../../components/text";
import Flex from "../../../../components/flex";
import Icon from "../../../../components/icon";
import ArrowRightIcon from "../../../../components/svg/arrowRightIcon";
import Input from "../../../../components/input";
import ViewBreakupCard from "../../../../components/viewBreakupCard";
import OptionSelectItem from "../../../../components/optionSelectItem";
import useHome from "../../hooks/home.hook";
import { useDispatch, useSelector } from "react-redux";
import {
  saveBuyData,
  saveNetworkFee,
  saveBuyReceiveData,
} from "../../../../redux/deposit/persistReducer";
import {
  getExchangeRatesFetch,
  getPlatformFeeFetch,
} from "../../../../redux/user/reducers";
import { saveCurrency } from "../../../../redux/user/persistReducer";
import { truncate } from "../../../../utils/util";

const BuyForm = (props) => {
  const dispatch = useDispatch();
  const { exchangeRateBuy, platformFee, platformCurrencies, profileDetails, markUp } =
    useSelector((state) => state?.userReducer);
  const {
    isViewBreakupToggle,
    onViewBreakupToggle,
    selectedBuyCurrency,
    handleBuyCurrencyChange,
  } = useHome();

  useEffect(() => {
    dispatch(getPlatformFeeFetch());
  }, []);


  const [originalAmount, setOriginalAmount] = useState('');
  const [convertedAmount, setConvertedAmount] = useState(0);
  const [marginAmount, setMarginAmount] = useState(0);
  const [minValue, setMinValue] = useState("");
  const getConvertedAmount = (receiveAmount) => {
    if (marginAmount) {
      const convertedValue = truncate(
        parseFloat(
          (formik.values.buyCurrencyAmount -
            formik.values.buyCurrencyAmount *
            (platformFee?.buyFeePercent || 0) *
            0.01 -
            propsNetworkFee * marginAmount) /
          marginAmount
        )
      );
      const nonNegativeConvertedAmount = Math.max(convertedValue, 0); // Ensure non-negative value
      setConvertedAmount(nonNegativeConvertedAmount);
    }
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const receiveAmount = useCallback((amount) => {
    getConvertedAmount(amount);
  });

  useEffect(() => {
    receiveAmount(originalAmount);
  }, [originalAmount, receiveAmount]);

  const reqCurrencyObj = platformCurrencies?.currecies[0];

  var selectedFee;
  if (props.selectedFee) {
    selectedFee = truncate(props.selectedFee);
  } else {
    selectedFee = truncate(
      reqCurrencyObj?.minimumBuyLimit + reqCurrencyObj?.networkFee
    );
  }
  var propsCode;
  if (props.code) {
    propsCode = props.code;
  } else {
    propsCode = reqCurrencyObj?.tokenType;
  }
  var propsNetworkFee;
  if (props.networkFee) {
    propsNetworkFee = props.networkFee;
  } else {
    propsNetworkFee = reqCurrencyObj?.networkFee;
  }

  const { buyReceiveData } = useSelector(
    (state) => state?.depositPersistReducer
  );

  useEffect(() => {
    if (
      !buyReceiveData?.token ||
      buyReceiveData?.token !== props.selectedCurrency
    ) {
      const selectedReceiveCurrency = {
        label: (
          <OptionSelectItem
            text={reqCurrencyObj?.currency}
            subText={reqCurrencyObj?.tokenName}
            code={reqCurrencyObj?.tokenType}
          />
        ),
        value: reqCurrencyObj?.blockchain,
        token: reqCurrencyObj?.currency,
        fee: reqCurrencyObj?.minimumBuyLimit + reqCurrencyObj?.networkFee,
        networkFee: reqCurrencyObj?.networkFee,
        code: reqCurrencyObj?.tokenType,
        source: reqCurrencyObj?.source,
      };
      dispatch(saveBuyReceiveData(selectedReceiveCurrency));
    }
  }, []);

  useEffect(() => {
    setMinValue(Math.ceil((selectedFee - propsNetworkFee) * exchangeRateBuy));
    setMarginAmount(parseFloat(exchangeRateBuy) + parseFloat(exchangeRateBuy * parseFloat(markUp)))
  }, [exchangeRateBuy, propsCode]);

  const formik = useFormik({
    initialValues: {
      buyCurrencyAmount: "",
    },
    validationSchema: Yup.object({
      buyCurrencyAmount: Yup.number()
        .moreThan(minValue, "min amount is " + (minValue + 1))
        .max(100000, "max amount is 100000")
        .required("Please enter a buy amount"),
    }),
    onSubmit: (values) => {
      dispatch(saveBuyData(values));
      dispatch(
        saveNetworkFee({
          price: values.buyCurrencyAmount,
          network: propsNetworkFee * marginAmount,
          payonrampFee:
            values.buyCurrencyAmount * platformFee?.buyFeePercent * 0.01,
          total:
            values.buyCurrencyAmount -
            values.buyCurrencyAmount * platformFee?.buyFeePercent * 0.01 -
            propsNetworkFee * marginAmount,
        })
      );
      dispatch(saveCurrency(convertedAmount));
      props.onOpenModal();
      setConvertedAmount("0");
      formik.resetForm();
    },
  });


  useEffect(() => {
    if (props.selectedCurrency) {
      dispatch(
        getExchangeRatesFetch({ values: { currency: props.selectedCurrency, source: props.source, blockchain: props.blockchain, fiat: props.selectedFiat.value, quantity: originalAmount ? originalAmount : "1" } })
      );
    }
  }, [props.selectedCurrency, props.blockchain, formik?.values?.buyCurrencyAmount, props.selectedFiat.value]);
  return (
    <>
      <Flex flexColumn justifyContent="center">
        <form onSubmit={formik.handleSubmit}>
          <div className="card-field">
            <div className="row d-flex align-items-center">
              <div className="col-5 col-md-5">
                <div className="form-box">
                  <Button
                    mb0
                    secondary
                    isSearchable={true}
                    text={
                      <>
                        {props.selectedFiat.value}
                        <span className="arrow-icon">
                          <svg
                            height="20"
                            width="20"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                            className="css-tj5bde-Svg"
                          >
                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                          </svg>
                        </span>
                      </>
                    }
                    type="button"
                    className="custom-button fiat-buy-btn"
                    onClick={props.onFiatClick}
                  />
                </div>
              </div>
              <div className="col-7 col-md-7">
                <div className="card-field-form-box">
                  <label className="card-field-label">
                    <Text
                      type="SPAN"
                      className="ml-8"
                      text={props.selectedFiat.value}
                    />
                  </label>
                  <Input
                    secondary
                    mb0
                    noLabel
                    type="number"
                    name="buyCurrencyAmount"
                    placeholder={minValue ? `min ${minValue + 1}` : ""}
                    value={formik?.values?.buyCurrencyAmount}
                    onChange={(value) => {
                      formik.handleChange("buyCurrencyAmount")(value);
                      receiveAmount(value?.target?.value);
                      setOriginalAmount(value?.target?.value);
                    }}
                    onBlur={formik.handleBlur}
                    mandatory
                    errormessage={
                      formik.touched.buyCurrencyAmount &&
                        formik.errors.buyCurrencyAmount
                        ? formik.errors.buyCurrencyAmount
                        : null
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <Flex justifyContent="center" className="mb-4">
            <Icon gray icon={<ArrowRightIcon down />} />
          </Flex>
          <div className="card-field bg-light-green">
            <div className="row d-flex align-items-center">
              <div className="col-5 col-md-5">
                <div className="form-box">
                  <Button
                    mb0
                    secondary
                    isSearchable={true}
                    text={
                      <>
                        {props.selectedCurrency}
                        {props.code !== null && <br />}
                        {props.code !== null && `on ${propsCode}`}{" "}
                        <span className="arrow-icon">
                          <svg
                            height="20"
                            width="20"
                            viewBox="0 0 20 20"
                            aria-hidden="true"
                            focusable="false"
                            className="css-tj5bde-Svg"
                          >
                            <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                          </svg>
                        </span>
                      </>
                    }
                    type="button"
                    className="custom-button buy-btn"
                    onClick={props.onClick}
                  />
                </div>
              </div>
              <div className="col-7 col-md-7">
                <div className="card-field-form-box">
                  <label className="card-field-label">
                    {props.selectedCurrency}
                  </label>
                  <Input
                    secondary
                    mb0
                    noLabel
                    type="text"
                    name="receiveCurrencyAmount"
                    placeholder="You will receive"
                    value={convertedAmount}
                    onChange={formik?.handleChange}
                    onBlur={formik?.handleBlur}
                    readonly={true}
                  />
                </div>
                <Flex flexColumn className="mt-12">
                  <Text
                    type="PARAGRAPH"
                    className="font-size-12 font-weight-500 mb-0"
                    text={`1 ${props.selectedCurrency} = ${props.selectedFiat.icon} ${exchangeRateBuy ? marginAmount : 0
                      }`}
                  />
                </Flex>
              </div>
            </div>
          </div>
          <Flex flexColumn>
            <Button
              type="submit"
              text="Proceed"
              className="mb-3"
              disabled={minValue + 1 > formik?.values?.buyCurrencyAmount}
            />
            <Button
              type="button"
              linkDark
              text={isViewBreakupToggle ? "Hide Breakup" : "View Breakup"}
              onClick={onViewBreakupToggle}
            />
            {isViewBreakupToggle && (
              <ViewBreakupCard
                active={isViewBreakupToggle}
                lists={[
                  {
                    text: `${props.selectedFiat.value + " Net Amount"}`,
                    number:
                      isNaN(formik.values.buyCurrencyAmount) ||
                        isNaN(platformFee?.buyFeePercent) ||
                        isNaN(propsNetworkFee) ||
                        isNaN(exchangeRateBuy) ||
                        formik.values.buyCurrencyAmount <= 0
                        ? `${props.selectedFiat.icon}0.0000`
                        : `${props.selectedFiat.icon}${truncate(
                          formik.values.buyCurrencyAmount -
                          formik.values.buyCurrencyAmount *
                          (platformFee?.buyFeePercent || 0) *
                          0.01 -
                          propsNetworkFee * marginAmount
                        )}`,

                  },
                  {
                    text: "PayOnRamp Fee",
                    number: `${props.selectedFiat.icon}${formik.values.buyCurrencyAmount > 0
                        ? truncate(
                          formik.values.buyCurrencyAmount *
                          platformFee?.buyFeePercent *
                          0.01
                        )
                        : "0.0000"
                      }`,
                  },
                  {
                    text: "Network Fee",
                    number:
                      isNaN(propsNetworkFee) ||
                        isNaN(marginAmount) ||
                        formik.values.buyCurrencyAmount <= 0
                        ? `${props.selectedFiat.icon}0.0000`
                        : `${props.selectedFiat.icon}${truncate(propsNetworkFee * marginAmount)}`,
                  },
                  {
                    text: "",
                    number: `(${propsNetworkFee} ${props.selectedCurrency})`,
                  },
                  {
                    text: "Total",
                    number: `${props.selectedFiat.icon}${formik.values.buyCurrencyAmount || 0}`,
                  },
                ]}
              />
            )}
          </Flex>
        </form>
      </Flex>
    </>
  );
};

export default BuyForm;
