import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flex from "../../../../components/flex";
import Text from "../../../../components/text";
import Button from "../../../../components/button";
import ChevronDownIcon from "../../../../components/svg/chevronDownIcon";

import { useDispatch, useSelector } from "react-redux";

import { getUserOTPFetch } from "../../../../redux/user/reducers";
import { saveUserEmail } from "../../../../redux/user/persistReducer";
import { APPLICATION_ID, FORMIK_REGEX } from "../../../../utils/constants";
import Input from "../../../../components/input";

const BuyLogin = (props) => {
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email()
        .required("Please enter a valid Email")
    }),
    onSubmit: (values) => {
      dispatch(getUserOTPFetch({ email: values?.email }));
      dispatch(saveUserEmail(values));
      props.handleSubmit();
    },
  });

  return (
    <div className="flow-box">
      <form onSubmit={formik.handleSubmit} className="height-100">
        <Flex flexColumn justifyContent="between" className="p-4 height-100">
          <Flex flexColumn>
            <Text type="H3" text="Buy Crypto" className="text-center mb-30" />
            <Text type="LEGEND" text="Login" />
            <div className="form-box">
              <div className="form-field">
                <Text
                  type="LABEL"
                  text={"Enter your email"}
                  className="form-label"
                />
                <Input
                  type="text"
                  name="email"
                  placeholder="Enter your email"
                  value={formik.values.email}
                  onChange={formik.handleChange} 
                />
                <div className="message-error">
                  <small>
                    {formik.touched.email && formik.errors.email
                      ? formik.errors.email
                      : null}
                  </small>
                </div>
              </div>
            </div>
          </Flex>
          <Flex flexColumn className="mt-3">
            <Button type="submit" text="Login" />
          </Flex>
        </Flex>
      </form>
    </div>
  );
};
export default BuyLogin;
