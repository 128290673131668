import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import "./App.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "react-phone-number-input/style.css";
import "./assets/scss/index.scss";
import { useSelector, useDispatch } from "react-redux";
// api
import { httpGet } from "./utils/axiosConnect";
// page
import Home from "./pages/home";
import History from "./pages/history";
import Profile from "./pages/profile";
import Support from "./pages/support";
import Partnership from "./pages/partnership";
import BecomeAPartner from "./pages/becomePartner";
import TermsAndConditions from "./pages/terms&conditions";
import PrivacyPolicy from "./pages/privacyPolicy";
import UserAgreement from "./pages/userAgreement";
import UserPolicy from "./pages/userPolicy";
import Tickets from "./pages/tickets";
import BuyCrypto from "./pages/buyCrypto";
import SellCrypto from "./pages/sellCrypto";
import AboutUs from "./pages/aboutus";
import Loader from "./pages/loader";
import ManualKyc from "./pages/manualKyc";

import { APPLICATION_ID } from "./utils/constants";

import { logoutFetch } from "./redux/user/reducers";

import { saveUserLoginDetails } from "./redux/user/persistReducer";

import { getAllCurrenciesSuccess } from "./redux/user/reducers";
import InteracDeposit from "./pages/interacDeposit";
import License from "./pages/license";

let idleTime = 0;
let idleInterval;
const params = new URLSearchParams(window.location.search);
const applicationId = params.get("applicationId");

function App() {
  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);
  const dispatch = useDispatch();

  const [login, setLogin] = useState(false);

  useEffect(() => {
    async function getDetails() {
      const response = await httpGet(`user/portal/profile`, {});

      const list = await httpGet(`http://ip-api.com/json/`);

      const currecies = await httpGet(
        `crypto/market/api/v1/${applicationId || APPLICATION_ID}/${
          list?.data?.countryCode || "US"
        }/currencies/`,
        {}
      );

      if (currecies) {
        dispatch(getAllCurrenciesSuccess(currecies?.data?.data));
      }
      if (response) dispatch(saveUserLoginDetails());
      setLogin(true);
    }

    getDetails();
  }, []);

  useEffect(() => {
    document.body.classList.add("body");
    if (isLoggedIn) {
      document.getElementById("app").addEventListener("mousemove", () => {
        idleTime = 0;
      });

      function logout() {
        idleTime++;
        if (idleTime >= 10) {
          clearInterval(idleInterval);
          idleTime = 0;
          document
            .getElementById("app")
            .removeEventListener("mousemove", () => {}, false);
          dispatch(logoutFetch());
        }
      }

      clearInterval(idleInterval);
      idleInterval = setInterval(logout, 60000);
    }
  }, [isLoggedIn]);

  return (
    <div className="App" id="app">
      {login ? (
        <BrowserRouter>
          <Routes>
            <Route path="/">
              <Route index={true} element={<Home />} />
              <Route
                path="history"
                element={isLoggedIn ? <History /> : <Navigate to="/" replace />}
              />
              {/* <Route path="profile" element={isLoggedIn ? <Profile /> : <Navigate to="/" replace />} /> */}
              <Route path="support" element={<Support />} />
              <Route path="partnership" element={<Partnership />} />
              <Route path="becomeAPartner" element={<BecomeAPartner />} />
              <Route
                path="termsAndConditions"
                element={<TermsAndConditions />}
              />
              <Route path="privacyPolicy" element={<PrivacyPolicy />} />
              <Route path="userAgreement" element={<UserAgreement />} />
              <Route path="userPolicy" element={<UserPolicy />} />
              <Route
                path="tickets"
                element={isLoggedIn ? <Tickets /> : <Navigate to="/" replace />}
              />
              {/* <Route path="tickets/detail" element={<TicketDetail />} /> */}
              <Route path="buy-crypto" element={<BuyCrypto />} />
              <Route path="sell-crypto" element={<SellCrypto />} />
              <Route path="about-us" element={<AboutUs />} />
              <Route path="license" element={<License />} />
              <Route
                path="interacDeposit"
                element={
                  isLoggedIn ? <InteracDeposit /> : <Navigate to="/" replace />
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default App;
