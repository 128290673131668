import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Flex from "../../../../components/flex";
import Text from "../../../../components/text";
import Button from "../../../../components/button";
import Textarea from "../../../../components/input/textarea";
import ViewBreakupCard from "../../../../components/viewBreakupCard";
import useHome from "../../hooks/home.hook";
import OptionSelectItem from "../../../../components/optionSelectItem";
import Input from "../../../../components/input";
import { useSelector, useDispatch } from "react-redux";
import { saveCurrency } from "../../../../redux/user/persistReducer";
import { saveBuyReceiveData } from "../../../../redux/deposit/persistReducer";
import { validateVoucherFetch } from '../../../../redux/withdrawal/reducer';
import { saveVoucherDetails } from '../../../../redux/user/reducers';

const ReviewPurchase = (props) => {
  const [isEdit, setIsEdit] = useState(true);
  const dispatch = useDispatch();
  const { buyData, walletAddress, buyReceiveData } = useSelector(
    (state) => state?.depositPersistReducer
  );
  const { convertionCurrent } = useSelector(
    (state) => state?.userPersistReducer
  );

  const { platformCurrencies, exchangeRateBuy, platformFee, markUp, walletInfo } = useSelector((state) => state?.userReducer);

  let allowedCurrenciesOptions = [];

  platformCurrencies?.currencies?.map((item, index) => {
    allowedCurrenciesOptions.push({
      label: item,
      value: item,
      key: index,
    });
  });

  const [convertedAmount, setConvertedAmount] = useState("");
  const [voucherApplied, setVoucherApplied] = useState(false);
  const [voucherPrice, setVoucherPrice] = useState('');
  const [voucherFee, setVoucherFee] = useState('');
  const [voucherNetwork, setVoucherNetwork] = useState('');

  const receiveAmount = (amount) => {
    getConvertedAmount(amount);
  };
  const getConvertedAmount = (receiveAmount) => {
    setConvertedAmount(parseFloat((receiveAmount / exchangeRateBuy).toFixed(4)));
    dispatch(
      saveCurrency(parseFloat((receiveAmount / exchangeRateBuy).toFixed(4)))
    );
  };
  const reqCurrencyObj = platformCurrencies?.currecies[0];

  const formik = useFormik({
    initialValues: {
      buyCurrencyAmount: buyData?.buyCurrencyAmount,
      receiveCurrencySelect: buyReceiveData?.token ?? reqCurrencyObj?.currency,
      receiveCurrencyAmount: convertionCurrent,
      walletAddress: walletInfo || walletAddress?.walletAddress,
      giftVoucher: '',
    },
    validationSchema: Yup.object({
      buyCurrencyAmount: Yup.number()
        .moreThan(0.99, "min amount is 100")
        .required("Please enter a buy amount"),
      walletAddress: Yup.string().required("Please enter a wallet address"),
      giftVoucher: Yup.string(),
    }),
    onSubmit: (values) => {
      props.handleSubmit();
    },
  });

  var selectedFee;
  if (props.selectedFee) {
    selectedFee = props.selectedFee;
  } else {
    selectedFee = reqCurrencyObj?.minimumBuyLimit + reqCurrencyObj?.networkFee;
  }

  var propsCode;
  if (props.code) {
    propsCode = props.code;
  } else if (
    !formik.values.receiveCurrencySelect &&
    reqCurrencyObj?.token == "USDT"
  ) {
    propsCode = reqCurrencyObj?.tokenType;
  } else {
    propsCode = null;
  }

  var propsNetworkFee;
  if (props.networkFee) {
    propsNetworkFee = props.networkFee;
  } else {
    propsNetworkFee = reqCurrencyObj?.networkFee;
  }

  var receiveCurrencySelectDefault;
  if (formik.values.receiveCurrencySelect) {
    receiveCurrencySelectDefault = formik.values.receiveCurrencySelect;
  } else {
    receiveCurrencySelectDefault = reqCurrencyObj?.currency;
  }

  useEffect(() => {
    if (
      !buyReceiveData?.token ||
      buyReceiveData?.token !== receiveCurrencySelectDefault
    ) {
      const selectedReceiveCurrency = {
        label: (
          <OptionSelectItem
            text={reqCurrencyObj?.currency}
            subText={reqCurrencyObj?.tokenName}
            code={reqCurrencyObj?.tokenType}
          />
        ),
        value: reqCurrencyObj?.blockchain,
        token: reqCurrencyObj?.currency,
        fee: reqCurrencyObj?.minimumBuyLimit + reqCurrencyObj?.networkFee,
        networkFee: reqCurrencyObj?.networkFee,
        code: reqCurrencyObj?.tokenType,
        source: reqCurrencyObj?.source
      };
      dispatch(saveBuyReceiveData(selectedReceiveCurrency));
    }
  }, []);

  const buyRate = Number(exchangeRateBuy) + Number(exchangeRateBuy * parseFloat(markUp));

  const calculateCrypto = () => {
    return (
      formik.values.buyCurrencyAmount -
      (formik.values.buyCurrencyAmount *
        (voucherFee || 0) *
        0.01) -
      (voucherNetwork * voucherPrice)
    ) / voucherPrice;
  };

  const callback = (value) => {
    if (value) {
      setVoucherPrice(value?.price ? value?.price : buyRate);
      setVoucherFee(value?.fee ? value?.fee : platformFee?.buyFeePercent);
      setVoucherNetwork(value?.networkFee ? value?.networkFee : propsNetworkFee);
    }
    setVoucherApplied(value);
  }

  const validateVoucher = () => {
    if (voucherApplied) {
      setVoucherApplied(false);
      dispatch(saveVoucherDetails(null));
      formik.values.giftVoucher = '';
      return;
    }
    dispatch(validateVoucherFetch({
      values: {
        voucher: formik.values.giftVoucher
      },
      callback
    }))
  }

  return (
    <div className="review-purchase-wrap">
      <form onSubmit={formik.handleSubmit} className="height-100">
        <Flex flexColumn justifyContent="between" className="p-4 height-100">
          <Flex flexColumn>
            <Text type="H3" text="Buy Crypto" className="text-center mb-30" />
            <Text type="LEGEND" text="Review Purchase" />
            <Flex justifyContent="between">
              <Text
                type="LABEL"
                className="font-size-14 font-weight-500 mb-1"
                text="Pay"
              />
            </Flex>
            <div className="card-field">
              <div className="row d-flex align-items-center">
                <div className="col-5 col-md-5">
                  <div className="form-box">
                    <Button
                      mb0
                      secondary
                      isSearchable={true}
                      text={
                        <>
                          {props.selectedFiat.value}
                          <span className="arrow-icon">
                            <svg
                              height="20"
                              width="20"
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                              focusable="false"
                              className="css-tj5bde-Svg"
                            >
                              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                            </svg>
                          </span>
                        </>
                      }
                      type="button"
                      className="custom-button fiat-buy-btn"
                    />
                  </div>
                </div>
                <div className="col-7 col-md-7">
                  <div className="card-field-form-box">
                    <label className="card-field-label">
                      <Text
                        type="SPAN"
                        className="ml-8"
                        text={props.selectedFiat.value}
                      />
                    </label>
                    <Input
                      secondary
                      mb0
                      noLabel
                      type="text"
                      name="buyCurrencyAmount"
                      placeholder="min 100"
                      value={formik.values.buyCurrencyAmount}
                      onChange={(value) => {
                        formik.handleChange("buyCurrencyAmount")(value);
                        receiveAmount(value.target.value);
                      }}
                      onBlur={formik.handleBlur}
                      mandatory
                      readonly={isEdit}
                      errormessage={
                        formik.touched.buyCurrencyAmount &&
                          formik.errors.buyCurrencyAmount
                          ? formik.errors.buyCurrencyAmount
                          : null
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <Text
              type="LABEL"
              className="font-size-14 font-weight-500 mb-1"
              text="Receive"
            />
            <div className="card-field">
              <div className="row d-flex align-items-center">
                <div className="col-5 col-md-5">
                  <div className="form-box">
                    <Button
                      mb0
                      secondary
                      isSearchable={true}
                      text={
                        <>
                          {receiveCurrencySelectDefault}
                          {propsCode !== null && <br />}
                          {propsCode !== null && `on ${propsCode}`}{" "}
                          <span className="arrow-icon">
                            <svg
                              height="20"
                              width="20"
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                              focusable="false"
                              className="css-tj5bde-Svg"
                            >
                              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
                            </svg>
                          </span>
                        </>
                      }
                      type="button"
                      className="buy-btn"
                      onClick={props.onClick}
                    />
                  </div>
                </div>
                <div className="col-7 col-md-7">
                  <div className="card-field-form-box">
                    <label className="card-field-label">
                      {receiveCurrencySelectDefault}
                    </label>
                    <Input
                      secondary
                      mb0
                      noLabel
                      type="text"
                      name="receiveCurrencyAmount"
                      placeholder="You will receive"
                      value={voucherApplied ? calculateCrypto().toFixed(4) : formik.values.receiveCurrencyAmount}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      mandatory
                      readonly={isEdit}
                      errormessage={
                        formik.touched.receiveCurrencyAmount &&
                          formik.errors.receiveCurrencyAmount
                          ? formik.errors.receiveCurrencyAmount
                          : null
                      }
                    />
                  </div>
                  <Flex flexColumn className="mt-12">
                    <Text
                      type="PARAGRAPH"
                      className="font-size-12 font-weight-500 mb-0"
                      text={`1 ${receiveCurrencySelectDefault} = ${props.selectedFiat.icon} ${exchangeRateBuy ? (voucherApplied?.price ? voucherApplied?.price : buyRate) : 0
                        }`}
                    />
                  </Flex>
                </div>
              </div>
            </div>
            <Text
              type="LABEL"
              className="font-size-14 font-weight-500 mb-1"
              text="Receive to Wallet"
            />
            <div className="form-box">
              <Textarea
                mb2
                type="text"
                name="walletAddress"
                placeholder=""
                value={formik.values.walletAddress}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                mandatory
                readonly={isEdit}
                rows={3}
                errormessage={
                  formik.touched.walletAddress && formik.errors.walletAddress
                    ? formik.errors.walletAddress
                    : null
                }
                disabled={true}
              />
            </div>
            <div className="form-box">
              <Flex alignItems="center" justifyContent="between">
                <div className="review-voucher">
                  <Input
                    type="text"
                    mb0
                    name="giftcard"
                    placeholder="Enter Gift Card"
                    value={formik.values.giftVoucher}
                    onChange={(value) => {
                      formik.handleChange("giftVoucher")(value);
                    }}
                    onBlur={formik.handleBlur}
                    errormessage={
                      formik.touched.giftVoucher &&
                        formik.errors.giftVoucher
                        ? formik.errors.giftVoucher
                        : null
                    }
                  />
                </div>
                <Button
                  type="button"
                  onClick={validateVoucher}
                  disabled={formik.values.giftVoucher === ''}
                  text={voucherApplied ? 'Remove' : 'Validate'} />
              </Flex>
            </div>
            {voucherApplied ? <div>
              <Text
                type="PARAGRAPH"
                className="font-size-12 font-weight-500 text-primary mb-0 mt-3"
                text="Gift Coupon applied Successfully !!!"
              />
            </div> : <></>}
            <Text
              type="PARAGRAPH"
              className="font-size-12 font-weight-500 text-dark-half mb-0 mt-3"
              text="Breakup"
            />
            {voucherApplied ? <ViewBreakupCard
              active
              lists={[
                {
                  text: `${props.selectedFiat.value + " Net Amount"}`,
                  number:
                    isNaN(formik.values.buyCurrencyAmount) ||
                      isNaN(platformFee?.buyFeePercent) ||
                      isNaN(propsNetworkFee) ||
                      isNaN(exchangeRateBuy)
                      ? `${props.selectedFiat.icon} 0`
                      : `${props.selectedFiat.icon}${(
                        formik.values.buyCurrencyAmount -
                        formik.values.buyCurrencyAmount *
                        (voucherFee || 0) *
                        0.01 -
                        voucherNetwork * voucherPrice
                      ).toFixed(4)}`,
                },
                {
                  text: "PayOnRamp Fee",
                  number: `${props.selectedFiat.icon}${(
                    formik.values.buyCurrencyAmount *
                    voucherFee *
                    0.01
                  ).toFixed(4)}`,
                },
                {
                  text: "Network Fee",
                  number:
                    isNaN(propsNetworkFee) || isNaN(exchangeRateBuy)
                      ? `${props.selectedFiat.icon} 0.00`
                      : `${props.selectedFiat.icon}${(voucherNetwork * voucherPrice).toFixed(4)}`,
                },
                {
                  text: "",
                  number: `(${voucherNetwork} ${receiveCurrencySelectDefault})`,
                },
                {
                  text: "Total",
                  number: `${props.selectedFiat.icon}${formik.values.buyCurrencyAmount}`,
                },
              ]}
            /> : <ViewBreakupCard
              active
              lists={[
                {
                  text: `${props.selectedFiat.value + " Net Amount"}`,
                  number:
                    isNaN(formik.values.buyCurrencyAmount) ||
                      isNaN(platformFee?.buyFeePercent) ||
                      isNaN(propsNetworkFee) ||
                      isNaN(exchangeRateBuy)
                      ? `${props.selectedFiat.icon} 0.00`
                      : `${props.selectedFiat.icon}${(
                        formik.values.buyCurrencyAmount -
                        formik.values.buyCurrencyAmount *
                        (platformFee?.buyFeePercent || 0) *
                        0.01 -
                        propsNetworkFee * buyRate
                      ).toFixed(4)}`,
                },
                {
                  text: "PayOnRamp Fee",
                  number: `${props.selectedFiat.icon}${(
                    formik.values.buyCurrencyAmount *
                    platformFee?.buyFeePercent *
                    0.01
                  ).toFixed(4)}`,
                },
                {
                  text: "Network Fee",
                  number:
                    isNaN(propsNetworkFee) || isNaN(exchangeRateBuy)
                      ? `${props.selectedFiat.icon} 0.00`
                      : `${props.selectedFiat.icon}${(propsNetworkFee * buyRate).toFixed(4)}`,
                },
                {
                  text: "",
                  number: `(${propsNetworkFee} ${receiveCurrencySelectDefault})`,
                },
                {
                  text: "Total",
                  number: `${props.selectedFiat.icon}${formik.values.buyCurrencyAmount}`,
                },
              ]}
            />}
          </Flex>
          <Flex flexColumn className="mt-3">
            <Button type="submit" text="Proceed" />
          </Flex>
        </Flex>
      </form>
    </div>
  );
};

export default ReviewPurchase;
