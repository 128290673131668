import { put, takeEvery, call } from "redux-saga/effects";
import { APPLICATION_ID, STATUS_CODE } from "../../utils/constants";
import { httpGet, httpPost, httpPostAuth } from "../../utils/axiosConnect";
import API from "../../utils/api";
import {
  saveUserLoginDetails,
  saveOTPReferenceDetails,
  savePhoneNumber,
  saveLoginDetails,
  clearUserLoginDetails,
  saveUserEmail,
} from "./persistReducer";
import { toasts } from "../../utils/toast";
import {
  getUserOTPSuccess,
  getUserOTPFailure,
  verifyUserOTPSuccess,
  verifyUserOTPFailure,
  getTransactionListSuccess,
  getTransactionListFailure,
  getAllCurrenciesFailure,
  getAllCurrenciesSuccess,
  getExchangeRatesSuccess,
  getExchangeRatesFailure,
  kycKeysSuccess,
  kycKeysFailure,
  updateKycStatusSuccess,
  updateKycStatusFailure,
  getPlatformFeeSuccess,
  getPlatformFeeFailure,
  deleteAccountSuccess,
  deleteAccountFailure,
  getPartnerDetailsSuccess,
  getPartnerDetailsFailure,
  getProfileDetailsSuccess,
  getProfileDetailsFailure,
  logoutSuccess,
  logoutFailure,
  submitKycSuccess,
  submitKycFailure,
  getApplicationId,
  getWalletAddress,
  getPhoneNumber,
  postInteracDetailsSuccess,
  postInteracDetailsFailure,
} from "./reducers";
import { updateWithdrawalTokenDetails } from "../withdrawal/persistReducer";

const params = new URLSearchParams(window.location.search);
const applicationId = params.get('applicationId');
const walletAddress = params.get('walletAddress');
const phoneNumber = params.get('phoneNumber');
const token = params.get('token');

function* getUserOTPSaga({ payload }) {
  try {
    const response = yield httpPost(API.USER.LOGIN, payload);
    if (response?.status === STATUS_CODE?.successful) {
      yield put(getUserOTPSuccess(response.data.data.otpReferenceKey));
      yield put(
        saveOTPReferenceDetails({
          otpReferenceKey: response.data.data.otpReferenceKey,
        })
      );
      yield put(
        saveUserEmail({
          email: payload?.email,
        })
      );
      payload?.callback(response);
    } else if (
      response?.status === STATUS_CODE?.unAuthorized ||
      response?.status === STATUS_CODE.forbidden
    ) {
      yield put(getUserOTPFailure());
    } else {
      yield put(getUserOTPFailure());
    }
  } catch (error) {
    yield put(getUserOTPFailure());
  }
}

function* verifyUserOTPSaga({ payload }) {
  try {
    const response = yield httpPost(API.USER.VERIFY_OTP, payload?.values);
    if (response?.status === STATUS_CODE?.successful) {
      localStorage.setItem('accessToken', response.data.data.accessToken);
      localStorage.setItem('refreshToken', response.data.data.refreshToken);
      localStorage.setItem('expiry', response.data.data.expiry);
      yield put(verifyUserOTPSuccess(response.data.data));
      yield put(saveOTPReferenceDetails(null));
      yield put(saveUserLoginDetails());
      toasts.success(response?.data?.message);
      payload.callback({
        responseStatus: true,
        kycApproved: response.data.data.kycApproved,
        kycStatus: response.data.data.kycStatus,
      });
    } else if (
      response?.status === STATUS_CODE?.unAuthorized ||
      response?.status === STATUS_CODE.forbidden ||
      response?.status === STATUS_CODE.badRequest
    ) {
      yield put(verifyUserOTPFailure());
      toasts.error(response?.data?.message);
      payload.callback({ responseStatus: false, isKYCApprovedUser: null });
    } else {
      yield put(verifyUserOTPFailure());
      toasts.error(response?.data?.message);
      payload.callback({ responseStatus: false, isKYCApprovedUser: null });
    }
  } catch (error) {
    yield put(verifyUserOTPFailure());
    payload.callback({ responseStatus: false, isKYCApprovedUser: null });
  }
}

function* transactionsListSaga({ payload }) {
  try {
    const response = yield httpGet(
      `${API.USER.GET_TRANSACTIONS_LIST}`,
      payload?.body
    );

    if (response?.status === STATUS_CODE?.successful) {
      yield put(getTransactionListSuccess(response?.data));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(getTransactionListFailure());
    } else {
      yield put(getTransactionListFailure());
    }
  } catch (error) {
    yield put(getTransactionListFailure());
  }
}

function* getPlatformFeeSaga() {
  try {
    const response = yield httpGet(
      `${API.CURRENCY.GET_PLATFORM_FEE}/${applicationId || APPLICATION_ID}/fee`,
      {},
      {}
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(getPlatformFeeSuccess(response?.data?.data));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(getPlatformFeeFailure());
    } else {
      yield put(getPlatformFeeFailure());
    }
  } catch (error) {
    yield put(getPlatformFeeFailure());
  }
}

function* getAllExchangeRateSaga({ payload }) {
  try {
    const response = yield httpGet(
      `${API.CURRENCY.EXCHANGE_RATE}`,
      payload?.values
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(getExchangeRatesSuccess(response?.data?.data));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(getExchangeRatesFailure());
    } else {
      yield put(getExchangeRatesFailure());
    }
  } catch (error) {
    yield put(getExchangeRatesFailure());
  }
}
function* kycKeysSaga({ payload }) {
  try {
    const response = yield httpPost(
      `${API.USER.GENERATE_KYC_KEYS}`,
      payload.type
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(kycKeysSuccess(response.data.data));
      payload?.initiateKYCVerification(response.data.data);
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(kycKeysFailure());
    } else {
      yield put(kycKeysFailure());
    }
  } catch (error) {
    yield put(kycKeysFailure());
  }
}

function* updateKycStatusSaga({ payload }) {
  try {
    const response = yield httpPost(
      `${API.USER.UPDATE_KYC_STATUS}`,
      payload.values
    );
    if (
      response?.status === STATUS_CODE?.successful &&
      response?.data?.status === "success"
    ) {
      yield put(updateKycStatusSuccess(response?.data));
      payload.callback(payload.values.status);
      yield put(
        saveLoginDetails({
          ...response?.data?.data,
          userInfo: true,
          kycApproved: true,
        })
      );
      yield call(getProfileDetailSaga);

      yield put(updateWithdrawalTokenDetails(response?.data?.data));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(updateKycStatusFailure());
      payload.callback("failed");
    } else {
      yield put(updateKycStatusFailure());
      payload.callback(payload.values.status);
      yield call(getProfileDetailSaga);
    }
  } catch (error) {
    yield put(updateKycStatusFailure());
    payload.callback("failed");
  }
}

function* deleteAccountSaga({ payload }) {
  try {
    const response = yield httpPost(
      `${API.USER.DELETE_BANK_ACCOUNT}`,
      { bankId: payload?.bankId }
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(deleteAccountSuccess(response.data.data));
      toasts.success(response?.data?.message);
      payload.callback();
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(deleteAccountFailure());
      toasts.error(response?.data?.message);
    } else {
      yield put(deleteAccountFailure());
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(deleteAccountFailure());
  }
}

function* partnerDetailsSaga({ payload }) {
  try {
    const response = yield httpPost(`${API.USER.PARTNER}`, {}, payload);
    if (response?.status === STATUS_CODE?.successful) {
      yield put(getPartnerDetailsSuccess(response.data.data));
      toasts.success(response?.data?.message);
      payload.callback();
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(getPartnerDetailsFailure());
      toasts.error(response?.data?.message);
    } else {
      yield put(getPartnerDetailsFailure());
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(getPartnerDetailsFailure());
  }
}

function* getProfileDetailSaga(payload) {
  try {
    const response = yield httpGet(
      `${API.USER.PROFILE_DETAILS}`,
      {}
    );
    if (response?.status === STATUS_CODE?.successful) {
      yield put(getProfileDetailsSuccess(response?.data?.data));
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(getProfileDetailsFailure());
    } else {
      yield put(getProfileDetailsFailure());
    }
  } catch (error) {
    yield put(getProfileDetailsFailure());
  }
}

function* postInteracDepositDetailSaga(payload) {
  try {
    const response = yield httpPost(
      `${API.USER.INTERAC_DEPOSIT}`,
      payload.payload
    );
    if (!response?.data.data.error) {
      yield put(postInteracDetailsSuccess(response.data));
      toasts.success(response?.data?.message);
    } else if (
      response?.status === (STATUS_CODE?.unAuthorized || STATUS_CODE.forbidden)
    ) {
      yield put(postInteracDetailsFailure());
    } else {
      yield put(postInteracDetailsFailure());
    }
  } catch (error) {
    yield put(postInteracDetailsFailure());
  }
}

function* logOutSaga({ payload }) {
  try {
    const response = yield httpPost(API.USER.LOGOUT, {});
    if (response?.status === STATUS_CODE?.successful) {
      yield put(logoutSuccess(response.data));
      payload.logoutCallback();
      yield put(clearUserLoginDetails());
    } else if (
      response?.status === STATUS_CODE?.unAuthorized ||
      response?.status === STATUS_CODE?.forbidden
    ) {
      yield put(logoutFailure());
      yield put(clearUserLoginDetails());
    } else {
      yield put(logoutFailure());
      yield put(clearUserLoginDetails());
    }
  } catch (error) {
    yield put(logoutFailure());
    yield put(clearUserLoginDetails());
  }
}

function* submitKyc({ payload }) {
  try {
    const response = yield httpPostAuth(API.KYC.MANUAL_KYC, payload?.formData, token);
    if (response?.status === STATUS_CODE?.successful) {
      yield put(submitKycSuccess());
      payload.callback();
    }
    else{
      toasts.error(response?.data?.message);
    }
  } catch (error) {
    yield put(submitKycFailure());
    window.parent.postMessage({ status: "FAILURE" }, "*");
    toasts.error('KYC Submit Failed !!! Please try again');
  }
}

function* userSaga() {
  yield takeEvery("userReducer/getUserOTPFetch", getUserOTPSaga);
  yield takeEvery("userReducer/verifyUserOTPFetch", verifyUserOTPSaga);
  yield takeEvery("userReducer/getTransactionListFetch", transactionsListSaga);
  yield takeEvery("userReducer/getPlatformFeeFetch", getPlatformFeeSaga);
  yield takeEvery("userReducer/getExchangeRatesFetch", getAllExchangeRateSaga);
  yield takeEvery("userReducer/kycKeysFetch", kycKeysSaga);
  yield takeEvery("userReducer/updateKycStatusFetch", updateKycStatusSaga);
  yield takeEvery("userReducer/delectAcccountFetch", deleteAccountSaga);
  yield takeEvery("userReducer/getPartnerDetailsFetch", partnerDetailsSaga);
  yield takeEvery("userReducer/getProfileDetailsFetch", getProfileDetailSaga);
  yield takeEvery("userReducer/postInteracDetails", postInteracDepositDetailSaga);
  yield takeEvery("userReducer/logoutFetch", logOutSaga);
  yield takeEvery("userReducer/submitKyc", submitKyc);
}
export default userSaga;
