import React, { useEffect, useState } from 'react'
import Modal from '../../../components/modal';
import Flex from '../../../components/flex';
import Text from '../../../components/text';
import { useFormik } from 'formik';
import * as Yup from "yup";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Input from '../../../components/input';
import Checkbox from '../../../components/input/checkbox';
import Button from '../../../components/button';
import DropdownCheckbox from '../../../components/dropdown/dropdownCheckbox';
import InputOption from '../../../components/inputOption';
import API from '../../../utils/api';
import { httpGet } from '../../../utils/axiosConnect';
import { postInteracDetails } from '../../../redux/user/reducers';
import { truncate } from '../../../utils/util';

const InteracDepostView = () => {
  const { isLoggedIn, email } = useSelector(
    (state) => state?.userPersistReducer
  );
  const [isStep2, setIsStep2] = useState(false);
  const [applicationOptions, setApplicationOptions] = useState([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    loadApplicationIds();
  }, []);

  const loadApplicationIds = async () => {
    try {
      const response = await httpGet(API.USER.INTERAC_DEPOSIT_APPLICATIONS);
      if (response?.status < 300) {
        const options = response?.data?.data?.map((e) => ({
          label: e?.applicationName,
          value: e?.applicationId,
          fee: e?.fiatFee,
          email: e?.email
        }));
        setApplicationOptions(options);
      }
    } catch (error) { }

  }

  const formOne = useFormik({
    initialValues: {
      receiveAccount: "payments@payonramp.com",
      check1: false,
      check2: false,
    },
    validationSchema: Yup.object({
      check1: Yup.boolean().oneOf([true], "Please confirm"),
      check2: Yup.boolean().oneOf([true], "Please confirm"),
      receiveAccount: Yup.string().required(
        "Please Enter the Interac e-Transfer Email"
      ),
    }),
    onSubmit: () => {
      setIsStep2(true);
    },
  });
  const formTwo = useFormik({
    initialValues: {
      amount: "",
      referenceTransferNo: "",
      applicationId: "",
      check3: false,
      userName: "",
    },
    validationSchema: Yup.object({
      referenceTransferNo: Yup.string().required(
        "Please Enter the reference transfer number"
      ),
      amount: Yup.number()
        .required("Please Enter Amount")
        .typeError("Invalid Amount"),
      check3: Yup.boolean().oneOf([true], "Please confirm"),
      applicationId: Yup.object().required("Please select application id"),
      userName: Yup.string()
    }),
    onSubmit: (values) => {
      const payload = {
        email: email.email,
        amount: values.amount,
        referenceTransferNo: values.referenceTransferNo,
        applicationId: values.applicationId?.value || "",
        receiveAccount: formOne.values.receiveAccount,
        userName: values.userName,
      };
      dispatch(postInteracDetails(payload));
      formOne.resetForm();
      formTwo.resetForm();
      navigate("/");
      setIsStep2(false);
    },
  });

  const handleApplicationChange = (e) => {
    formTwo.setFieldValue("applicationId", e);
    if (e) {
      formOne.setFieldValue("receiveAccount", e.email);
    }
  };

  return (
    <div className="payment-method">
      <Modal smallMedium minHeight>
        <Flex flexColumn>
          <Text type="H3" text="Deposit Only" className="text-center mt-20" />
        </Flex>
        {!isStep2 && (
          <form onSubmit={formOne.handleSubmit}>
            <Flex flexColumn>
              <div className="row row-mrl-6" style={{ padding: "30px" }}>
                <div className="col-12">
                  <div className="form-box">
                    <InputOption
                      list={applicationOptions}
                      label={"Application ID"}
                      name="applicationId"
                      value={formTwo.values.applicationId}
                      onChange={(e) => {
                        formTwo.handleChange({
                          target: { value: e, name: "applicationId" },
                        });
                        handleApplicationChange(e);
                      }}
                      mandatory
                      placeholder={"Select application id"}
                      errormessage={
                        formTwo.errors.applicationId
                          ? formTwo.errors.applicationId
                          : null
                      }
                    />
                    <Input
                      label="Send the Interac e-Transfer to :"
                      type="text"
                      name="receiveAccount"
                      placeholder=""
                      value={formOne.values.receiveAccount}
                      onChange={formOne.handleChange}
                      onBlur={formOne.handleBlur}
                      mandatory
                      errormessage={
                        formOne.touched.receiveAccount &&
                          formOne.errors.receiveAccount
                          ? formOne.errors.receiveAccount
                          : null
                      }
                    />
                  </div>
                  <Checkbox
                    value={formOne.values.check1}
                    label={
                      <Flex flexWrap>
                        <Text
                          type="SPAN"
                          text="The name on the bank account I am sending an e-Transfer matches the name on my PayOnRamp Account."
                          className="d-inline-flex"
                        />
                      </Flex>
                    }
                    id="check1"
                    isChecked={formOne.values.check1}
                    onChange={formOne.handleChange}
                    errormessage={
                      formOne.touched.check1 && formOne.errors.check1
                        ? formOne.errors.check1
                        : null
                    }
                  />
                  <Checkbox
                    value={formOne.values.check2}
                    label={
                      <Flex flexWrap>
                        <Text
                          type="SPAN"
                          text="I understood that if I send an e-Transfer from a bank account that is not under my name, my PayOnRamp account will be banned."
                          className="d-inline-flex"
                        />
                      </Flex>
                    }
                    id="check2"
                    isChecked={formOne.values.check2}
                    onChange={formOne.handleChange}
                    errormessage={
                      formOne.touched.check2 && formOne.errors.check2
                        ? formOne.errors.check2
                        : null
                    }
                  />
                </div>
              </div>
              <Flex flexColumn className="m-3">
                <Button type="submit" text="Next" />
              </Flex>
            </Flex>
          </form>
        )}
        {isStep2 && (
          <form onSubmit={formTwo.handleSubmit}>
            <Flex flexColumn>
              <div className="row row-mrl-6" style={{ padding: "30px" }}>
                <div className="col-12">
                  <Input
                    label="Enter Amount :"
                    type="text"
                    name="amount"
                    placeholder=""
                    value={formTwo.values.amount}
                    onChange={formTwo.handleChange}
                    onBlur={formTwo.handleBlur}
                    mandatory
                    errormessage={
                      formTwo.touched.amount && formTwo.errors.amount
                        ? formTwo.errors.amount
                        : null
                    }
                  />
                  {formTwo?.values?.applicationId?.fee && (
                    <div className="m-0 mb-2 font-size-14">
                      {formTwo?.values?.applicationId?.fee &&
                        `Fee: ${truncate(
                          formTwo?.values?.applicationId?.fee * 100
                        )}% `}
                      <span className="font-size-12">
                        {formTwo.values.amount &&
                          ` ≈ (${truncate(
                            formTwo.values.amount *
                            formTwo?.values?.applicationId?.fee
                          )})`}
                      </span>
                      <br />
                      {formTwo.values.amount && `Net Amount: ${truncate(
                        formTwo?.values?.amount -
                        formTwo?.values?.amount *
                        formTwo?.values?.applicationId?.fee
                      )}`}
                    </div>
                  )}

                  <Input
                    label="Enter the Reference Transfer Number :"
                    type="text"
                    name="referenceTransferNo"
                    placeholder=""
                    value={formTwo.values.referenceTransferNo}
                    onChange={formTwo.handleChange}
                    onBlur={formTwo.handleBlur}
                    mandatory
                    errormessage={
                      formTwo.touched.referenceTransferNo &&
                        formTwo.errors.referenceTransferNo
                        ? formTwo.errors.referenceTransferNo
                        : null
                    }
                  />
                  <Input
                    label="Enter Your Name:"
                    type="text"
                    name="userName"
                    placeholder="Enter Your name"
                    value={formTwo.values.userName}
                    onChange={formTwo.handleChange}
                    onBlur={formTwo.handleBlur}
                  />
                  <Checkbox
                    value={formTwo.values.check3}
                    label={
                      <Flex flexWrap>
                        <Text
                          type="SPAN"
                          text={`I understand that failure to enter ${formTwo.values.referenceTransferNo} as my memo/message is my e-transfer will cause along delay.`}
                          className="d-inline-flex"
                        />
                      </Flex>
                    }
                    id="check3"
                    isChecked={formTwo.values.check3}
                    onChange={formTwo.handleChange}
                    errormessage={
                      formTwo.touched.check3 && formTwo.errors.check3
                        ? formTwo.errors.check3
                        : null
                    }
                  />
                </div>
              </div>
              <Flex
                flexRow
                className="m-4 "
                alignItems="center"
                justifyContent="between"
              >
                <Button
                  text="Back"
                  onClick={() => {
                    setIsStep2(false);
                  }}
                  className="flex-fill"
                />
                <Button type="submit" text="Next" className="flex-fill" />
              </Flex>
            </Flex>
          </form>
        )}
      </Modal>
    </div>
  );
};

export default InteracDepostView