import React from 'react'
import CanadaFlag from "../../assets/img/canada.png"
import IndiaFlag from "../../assets/img/india.png"
import EuropeFlag from "../../assets/img/europe.png"
import Layout from '../include/layout';
const sections = [
  {
    heading: 'EUROPE',
    image: EuropeFlag,
    paragraph: 'PayOnRamp is offering its services with trade, earn and borrow in the EUROPEAN region. It will continue to deliver trust and transparency with its regulated and compliant services for all EUROPEANS. ',
    licenseName: 'European VASP License: QWSA123 '
  },
  {
    heading: 'CANADA',
    image: CanadaFlag,
    paragraph: 'PayOnRamp is built with trust and transparency. We extend the same trust and transparency through ensuring PayOnRamp is compliant with regulations and licenses. ',
    licenseName: 'FINTRAC License: ABCD0987'
  },
  {
    heading: 'INDIA',
    image:  IndiaFlag,
    paragraph: 'PayOnRamp is offering its services with trade, earn and borrow in INDIA. It will continue to deliver trust and transparency with its regulated and compliant services for all INDIANS. INDIAN FIU License',
    licenseName: 'INDIAN FIU License: CFX4567'
  },
];

function License() {
  return (
    <Layout>
      <div className="Page">
        {sections.map((section, index) => (
          <div key={index} className="section">
            <h2>{section.heading}</h2>
            <img src={section.image} alt={`Image for ${section.heading}`} className='image'/>
            <p>{section.paragraph}</p>
            <b>{section.licenseName}</b>
          </div>
        ))}
      </div>
    </Layout>
  );
}

export default License;
