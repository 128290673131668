import React from "react";
import IconTextDescCard from "../../../../../components/iconTextDescCard";
import ApprovedImg from "../../../../../assets/img/account-approved-img.png";
import Flex from "../../../../../components/flex";
import { useNavigate } from "react-router-dom";

const AccountApproved = (props) => {
  const navigate = useNavigate();
  return (
    <Flex
      flexColumn
      justifyContent="center"
      alignItems="center"
      className="h-100"
    >
      <IconTextDescCard
        {...props}
        src={ApprovedImg}
        text="KYC Documents Submitted"
        description="Your KYC documents are under review. We'll be in touch soon with the results."
        btnText="Okay"
        onClick={() => {
          props.onClick();
          navigate("/profile");
        }}
      />
    </Flex>
  );
};

export default AccountApproved;
