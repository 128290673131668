import React from 'react';
import {  useNavigate } from "react-router-dom";

// components
import Section from '../../../components/section';
import Flex from '../../../components/flex';
import Image from '../../../components/image';
import Text from '../../../components/text';
import Button from '../../../components/button';
import MissionBreakBarrierCard from '../../../components/MissionBreakBarrierCard';

// images
import AboutusImg from '../../../assets/img/hero-aboutus-img.png';
import HowweplanImg from '../../../assets/img/safety-money-img.png';
import EvolutionImg from '../../../assets/img/evolutionary-experience-img.png';
import CommunicatingEffectivelyImg from '../../../assets/img/communication-effectively-img.png';
import NationwideImg from '../../../assets/img/inclusivity-nationwide-img.png';

const AboutusView = () => {
  const navigate = useNavigate();

  return (
    <>
      <Section containerWidth1100 className="section-hero-aboutus">
        <Flex flexColumn>
          <div className="row d-flex justify-content-between flex-column-reverse flex-md-row">
            <div className="col-12 col-md-5">
              <div className="hero-aboutus-left">
                <div className="hero-aboutus-img">
                  <Image src={AboutusImg} alt="Image" />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-7">
              <div className="hero-aboutus-caption">
                <Text
                  type="H1"
                  className="hero-caption-title"
                  text="Welcome to PayOnRamp - Your Trusted Crypto Onramp Gateway!"
                />
                <Text
                  type="PARAGRAPH"
                  text="Integrate cryptocurrencies seamlessly into your business with PayOnRamp- the leading payment gateway for crypto assets and fiat transactions. Our innovative platform provides a robust solution, empowering businesses of every scale to embrace the world of cryptocurrencies alongside conventional fiat currencies. Whether you're an online retailer or a service provider, PayOnRamp has got you covered. We are your ultimate ally, ensuring you never miss out on the incredible opportunities offered by digital currencies."
                />
                <Text
                  type="PARAGRAPH"
                  text="While Onramp is not for everyone at the moment, we want to take the first step and change that. We are here to transform the digital transactional experience while showing you the “hows” and “whys” of it."
                />
              </div>
            </div>
          </div>
        </Flex>
      </Section>
      <Section containerWidth1100 className="section-book-a-call">
        <Flex flexColumn justifyContent="center" alignItems="center">
          <Text
            type="H2"
            className="section-title text-center"
            text="Book a call with us and provide your business with on ramp & off ramp solutions, and build your way to success!"
          />
          <Button
           text="Book a Call"
           type="button"
           onClick = {() => navigate("/becomeAPartner")}
           white />
        </Flex>
      </Section>
      <Section containerWidth1100 className="section-how-we-plan">
        <div className="row align-items-center">
          <div className="col-12 col-md-7">
            <Flex flexColumn className="section-content-left">
              <Text
                type="H2"
                className="font-size-36 font-weight-700 mb-1"
                text="And Here’s How We Plan on Doing it:"
              />
              <Text
                type="H5"
                className="text-pink mb-4"
                text="Enlighten, Assure, & Engage!"
              />
              <p className="mb-4">
                <strong>
                  <i>Enlighten</i>
                </strong>{' '}
                by creating awareness about the world of digital transactions
                and the benefits of onramps. The world is moving ahead, and so
                should we, by adopting new ways and keeping up with the latest
                technology.
              </p>
              <p className="mb-4">
                Give{' '}
                <strong>
                  <i>assurance</i>
                </strong>{' '}
                and instil confidence by ensuring secure and transparent
                transactions, safeguarding user information and guaranteeing
                data privacy.
              </p>
              <p className="mb-0">
                And{' '}
                <strong>
                  <i>engage</i>
                </strong>{' '}
                by actively seeking partnerships with other platforms and
                businesses, promoting collaborations and establishing a unifying
                force that widens the spectrum of onramp transactions.
              </p>
            </Flex>
          </div>
          <div className="col-12 col-md-5">
            <div className="section-right-image">
              <Image src={HowweplanImg} alt="Image" />
            </div>
          </div>
        </div>
      </Section>
      <Section containerWidth1100 className="section-mission-break-barriers">
        <div className="row mb-5">
          <div className="col-12 col-md-9 mx-auto text-center">
            <Text
              type="H2"
              className="section-title font-weight-700"
              text="On a Mission to Break The Barriers!"
            />
            <Text
              type="PARAGRAPH"
              text="The mission at PayOnRamp is to create a user experience so buttery-smooth, it greases the wheels of any business and steers them straight to success. The plan follows a customer-centric approach that is curated to target various kinds of audiences and make Onramp more accessible!"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-4">
            <MissionBreakBarrierCard
              icon={EvolutionImg}
              text="Creating an Evolutionary Experience:"
              description="We are determined to bring about a paradigm shift in usability, simplifying even the most intricate processes. By introducing this groundbreaking innovation, we aim to eliminate complexities and make navigating the Web3 world a breeze for every user."
            />
          </div>
          <div className="col-12 col-md-4">
            <MissionBreakBarrierCard
              icon={CommunicatingEffectivelyImg}
              text="Communicating Effectively:"
              description="Our focus is to target individuals with efficient communication strategies. By effectively conveying the value and potential of Web3, we aim to engage and empower individuals, creating experiences that resonate with many."
            />
          </div>
          <div className="col-12 col-md-4">
            <MissionBreakBarrierCard
              icon={NationwideImg}
              text="Global Inclusivity:"
              description="Our ultimate goal is to make Web3 accessible to every corner of the world. We aspire to break down barriers and provide equal opportunities for every individual to participate in the exciting possibilities of the decentralised web."
            />
          </div>
        </div>
        <div className="joinus-wrp">
          <Text
            type="H2"
            className="joinus-title"
            text="Join PayOnRamp on this transformative journey, as we pave the way for a Web3 revolution that empowers individuals and businesses, and leaves no one behind."
          />
        </div>
      </Section>
    </>
  );
};

export default AboutusView;
