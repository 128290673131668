import { PUBLIC_URL } from "./constants";

const API = {
  CPG: {
    USER: {
      LOGIN: `${PUBLIC_URL}/user/login/otp`,
      VERIFY_OTP: `${PUBLIC_URL}/user/api/v2/login/otp/verify`,
      GET_QR: `${PUBLIC_URL}/user/auth/getQRCode`,
      INITIATE_USER_TRANSACTION: `${PUBLIC_URL}/user/api/v2/transaction/initiate`,
      BANK_DETAILS: `${PUBLIC_URL}/user/auth/getBankDetails `,
      GENERATE_REFRESH_TOKEN: `${PUBLIC_URL}/user/generateNewRefreshToken`,
      VALIDATE_VOUCHER: `${PUBLIC_URL}/user/validate-gift-voucher`
    },
  },
  USER: {
    LOGIN: `${PUBLIC_URL}/user/portal/login/otp`,
    VERIFY_OTP: `${PUBLIC_URL}/user/portal/login/otp/verify`,
    GET_TRANSACTIONS_LIST: `${PUBLIC_URL}/user/portal/transactions`,
    ADD_BANK_ACCOUNT: `${PUBLIC_URL}/user/portal/add/bank`,
    GET_BANK_DETAILS: `${PUBLIC_URL}/user/portal/get/banks`,
    GENERATE_KYC_KEYS: `${PUBLIC_URL}/user/auth/kyc/token`,
    UPDATE_KYC_STATUS: `${PUBLIC_URL}/user/auth/kyc/status`,
    CONFIRM_BANK:`${PUBLIC_URL}/user/transaction/bank/confirm`,
    DELETE_BANK_ACCOUNT: `${PUBLIC_URL}/user/portal/bank/inactivate`,
    PARTNER: `${PUBLIC_URL}/public/api/v1/partner/request`,
    PROFILE_DETAILS: `${PUBLIC_URL}/user/portal/profile`,
    LOGOUT: `${PUBLIC_URL}/user/portal/logout`,
    ADD_TICKET: `${PUBLIC_URL}/user/portal/create-ticket`,
    FETCH_TICKET: `${PUBLIC_URL}/user/portal/tickets`,
    INTERAC_DEPOSIT: `${PUBLIC_URL}/interac-deposit`,
    INTERAC_DEPOSIT_APPLICATIONS: `${PUBLIC_URL}/interac-deposit/applications`
  },
  CURRENCY: {
    GET_PLATFORM_CURRENCIES:`${PUBLIC_URL}/crypto/market/api/v1`,
    EXCHANGE_RATE: `${PUBLIC_URL}/crypto/market/api/v1/exchangeRate`,
    GET_PLATFORM_FEE :`${PUBLIC_URL}/crypto/market/api/v1`,
  },
  TTRANSACTIONS: {
    UTR_TRANSFER: `${PUBLIC_URL}/transactions/auth/submitUTR`,
    PAYMENT_OPTIONS: `${PUBLIC_URL}/transactions/auth/getAvailablePaymentMethods`,
    WALLET_DEATILS: `${PUBLIC_URL}/transactions/auth/crypto/getMerchantWalletAddress`,
    CRYPTO_DEPOSIT: `${PUBLIC_URL}/transactions/auth/verifyCryptoDeposit`,
    TRANSACTION_STATUS: `${PUBLIC_URL}/transactions/auth/checkTransactionStatus`,
    TRANSACTION_DETAILS: `${PUBLIC_URL}/transactions/receipt`
  },
  KYC: {
    MANUAL_KYC: `${PUBLIC_URL}/user/merchant-user-kyc`
  }
};
export default API;
