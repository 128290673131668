import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// reducers
import { initiateTransactionFetch } from '../../../redux/withdrawal/reducer';
import { saveWithdrwalTokenDetails } from '../../../redux/withdrawal/persistReducer';
import { clearUserLoginDetails } from "../../../redux/user/persistReducer";
// components
import Section from '../../../components/section';
import Text from '../../../components/text';
import Card from '../../../components/card';
import Flex from '../../../components/flex';
import Button from '../../../components/button';
import MenuLineIcon from '../../../components/svg/menuLineIcon';
import BuyForm from '../../home/components/buyForm';
import CloseFillIcon from '../../../components/svg/closeFIllIcon';
import Icon from '../../../components/icon';
import NavTextIconList from '../../../components/navTextIconList';
import ArrowDownIcon from '../../../components/svg/arrowDownIcon';
import HistoryFillIcon from '../../../components/svg/historyFillIcon';
import FileUserIcon from '../../../components/svg/fileUserIcon';
import ShakeFillIcon from '../../../components/svg/shakeFillIcon';
import BankFillIcon from '../../../components/svg/bankFillIcon';
import SupportIcon from '../../../components/svg/supportIcon';
import LogoutBoxIcon from '../../../components/svg/logoutBoxIcon';
import Modal from '../../../components/modal';
import CancelPurchase from '../../home/components/buy/cancelPurchase';
import PurchasePending from '../../home/components/buy/purchaseStatus/pending';
import BuyKYC from '../../home/components/buy/buyKYC';
import ReviewPurchase from '../../home/components/buy/reviewPurchase';
import BuyLogin from '../../home/components/buy/login';
import WalletDetails from '../../home/components/buy/walletDetails';
import PurchaseSuccess from '../../home/components/buy/purchaseStatus/success';
import BuyOTP from '../../home/components/buy/otp';
import BuyPaymentMethod from '../../home/components/buy/paymentMethod';
import BuyReceivingINR from '../../home/components/buy/receivingINR';
import BitCoinLoading from '../../../components/loading/bitcoinLoading';
import KYCSuccess from '../../home/components/buy/buyKYC/kycSuccess';
import SearchCryptocurrencies from '../../../components/searchCryptocurrencies';
// hooks
import useHome from '../../home/hooks/home.hook';
// utils
import { APPLICATION_ID } from '../../../utils/constants';
import AccountUnderVerification from '../../authentication/accountUnderVerification';

const BuyCryptoView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { buyData, walletAddress, buyReceiveData } = useSelector(
    (state) => state?.depositPersistReducer
  );
  const { exchangeRateBuy, markUp, applicationId, walletInfo, dexQuote } = useSelector(
    (state) => state?.userReducer
  );
  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);
  const Loading = useSelector((state) => state.withdrawalReducer.isLoading);

  const { phoneNumber } = useSelector((state) => state.userPersistReducer);

  const callback = (res) => {
    if (res.status === 200) {
      if (res?.data?.verifyUser === true) {
        dispatch(clearUserLoginDetails());
        onOpenLogin();
      } else {
        dispatch(saveWithdrwalTokenDetails(res?.data));
        BuySellCallback(res?.data);
      }
    }
  };

  const {
    isOpenModal,
    onOpenModal,
    onCloseModal,
    onOpenLogin,
    isReviewPurchase,
    onOpenReviewPurchase,
    isLogin,
    goToBackReviewPurchase,
    isOTP,
    onOpenOTP,
    isBuyKYC,
    goToBackLogin,
    isPaymentMethod,
    isCancelPurchase,
    onCancelPurchaseCancel,
    onProceedCancelPurchase,
    goToBackOTP,
    isReceivingINR,
    onOpenReceivingINR,
    isPurchasePending,
    onOpenPurchasePending,
    isPurchaseSuccess,
    handleClickDone,
    onClosePurchasePending,
    isMenuLine,
    onCloseMenuLine,
    isKYCSuccess,
    onOpenKycSuccess,
    VerifyOtpCallback,
    selectedReceiveCurrency,
    onOpenSearchCryptocurrencies,
    onCloseSearchCryptocurrencies,
    handleReceiveCurrencyChange,
    isSearchCryptocurrencies,
    goToBackWalletDetails,
    onOpenMenuLine,
    BuySellCallback,
    openKYCPendingModal,
    onCloseKYCPendingModal,
    verifyPaymentMethodCallback,
  } = useHome();

  const onInitialization = () => {
    if (isLoggedIn) {
      dispatch(
        initiateTransactionFetch({
          values: {
            amount: buyData?.buyCurrencyAmount,
            currency: buyReceiveData?.token,
            blockchain: buyReceiveData?.value,
            applicationId: applicationId || APPLICATION_ID,
            referenceId: '6e2d2626-55bb-41d5-9892-318e48a45f4b',
            transferType: 'BUY',
            phoneNumber: phoneNumber?.mobileNumber,
            walletAddress: walletInfo || walletAddress?.walletAddress,
            coinPrice:
              Number(exchangeRateBuy) +
              Number(exchangeRateBuy * parseFloat(markUp)),
              dexQuote: dexQuote,
          },
          callback,
        })
      );
    } else {
      onOpenLogin();
    }
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const goto = (item) => {
    if (applicationId) {
      openInNewTab(`https://payonramp.com`);
      return;
    }
    isLoggedIn ? navigate(`/${item}`) : navigate('/');
  };

  return (
    <>
      <Section className="section-hero section-new-hero">
        <div className="hero-form-wrapper">
          <div className="hero-form-main">
            <Card className="position-relative">
              <Flex justifyContent="between">
                <Flex>
                  <Button
                    linkBlack
                    type="button"
                    text={<MenuLineIcon />}
                    onClick={onOpenMenuLine}
                  />
                  <Text
                    type="H3"
                    text="PayOnRamp"
                    className="mb-0 ml-3 text-dark-100"
                  />
                </Flex>
              </Flex>
              <Text type="H3" className="mt-2 mb-2" text="Buy" />
              <BuyForm
                onOpenModal={onOpenModal}
                onClick={onOpenSearchCryptocurrencies}
                selectedCurrency={selectedReceiveCurrency?.token}
                selectedFee={selectedReceiveCurrency?.fee}
                networkFee={selectedReceiveCurrency?.networkFee}
                code={selectedReceiveCurrency?.code}
                source={selectedReceiveCurrency?.source}
              />
              {isSearchCryptocurrencies && (
                <SearchCryptocurrencies
                  goToBack={onCloseModal}
                  onClose={onCloseSearchCryptocurrencies}
                  selectedCurrency={selectedReceiveCurrency?.token}
                  onChange={(selectedOption) => {
                    handleReceiveCurrencyChange(selectedOption);
                    onCloseSearchCryptocurrencies();
                  }}
                  direction="buy"
                />
              )}
              {isMenuLine && (
                <div className="buysell-navmenu-wrp">
                  <Flex justifyContent="end">
                    <Button
                      linkBlack
                      text={<Icon white icon={<CloseFillIcon />} />}
                      onClick={onCloseMenuLine}
                    />
                  </Flex>
                  <NavTextIconList
                    lists={[
                      {
                        id: 1,
                        text: 'Buy',
                        icon: <ArrowDownIcon />,
                        onClick: () => navigate('/buy-crypto'),
                      },
                      {
                        id: 2,
                        text: 'Sell',
                        icon: <ArrowDownIcon up />,
                        onClick: () => navigate('/sell-crypto'),
                      },
                      {
                        id: 3,
                        text: 'History',
                        icon: <HistoryFillIcon />,
                        onClick: () => goto('history'),
                      },
                      {
                        id: 4,
                        text: 'Profile',
                        icon: <FileUserIcon />,
                        onClick: () => goto('profile'),
                      },
                      {
                        id: 5,
                        text: 'Partner',
                        icon: <ShakeFillIcon />,
                        onClick: () => goto('partnership'),
                      },
                      {
                        id: 6,
                        text: 'Bank Accounts',
                        icon: <BankFillIcon />,
                        onClick: () => goto('profile'),
                      },
                      {
                        id: 7,
                        text: 'Support',
                        icon: <SupportIcon />,
                        onClick: () => goto('support'),
                      },
                    ]}
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
      </Section>
      {isOpenModal && (
        <Modal
          smallMedium
          minHeight
          onClose={isReceivingINR ? onClosePurchasePending : onCloseModal}
        >
          {isCancelPurchase ? (
            <CancelPurchase
              onCancel={onCancelPurchaseCancel}
              onProceed={onProceedCancelPurchase}
            />
          ) : Loading ? (
            <BitCoinLoading />
          ) : isPurchaseSuccess ? (
            <PurchaseSuccess handleClick={handleClickDone} />
          ) : isPurchasePending ? (
            <PurchasePending handleClick={onClosePurchasePending} />
          ) : isReceivingINR ? (
            <BuyReceivingINR handleClick={onOpenPurchasePending} />
          ) : isPaymentMethod ? (
            <BuyPaymentMethod
              handleSubmit={onOpenReceivingINR}
              verifyPaymentMethodCallback={verifyPaymentMethodCallback}
              goToBack={goToBackOTP}
            />
          ) : openKYCPendingModal ? (
            <AccountUnderVerification onClick={onCloseKYCPendingModal} />
          ) : isKYCSuccess ? (
            <KYCSuccess />
          ) : isBuyKYC && !openKYCPendingModal ? (
            <BuyKYC
              handleSubmit={onOpenKycSuccess}
              handleClose={onCloseModal}
            />
          ) : isOTP ? (
            <BuyOTP
              // handleSubmit={onOpenBuyKYC}
              goToBack={goToBackLogin}
              VerifyOtpCallback={VerifyOtpCallback}
            />
          ) : isLogin ? (
            <BuyLogin
              handleSubmit={onOpenOTP}
              goToBack={goToBackReviewPurchase}
            />
          ) : isReviewPurchase ? (
            <ReviewPurchase
              handleSubmit={onInitialization}
              goToBack={goToBackWalletDetails}
              networkFee={selectedReceiveCurrency?.networkFee}
              code={selectedReceiveCurrency?.code}
            />
          ) : (
            <WalletDetails handleSubmit={onOpenReviewPurchase} />
          )}
        </Modal>
      )}
    </>
  );
};

export default BuyCryptoView;
