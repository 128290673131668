import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  saveBuyReceiveData,
  saveBuyFiatData,
  saveWalletAddress,
} from "../../../redux/deposit/persistReducer";
import {
  saveSellReceiveData,
  saveSellFiatData,
  saveUserBank,
} from "../../../redux/withdrawal/persistReducer";

const useHome = () => {
  const dispatch = useDispatch();

  const { platformCurrencies, walletInfo } = useSelector((state) => state?.userReducer);

  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);

  const [navTabStatus, setNavTabStatus] = useState({
    navTabItemType: '0',
  });
  const navTabHandleclick = (item) => {
    setNavTabStatus({
      navTabItemType: item.id,
    });
  };
  const [isViewBreakupToggle, setIsViewBreakupToggle] = useState(false);
  const onViewBreakupToggle = () => {
    setIsViewBreakupToggle((state) => !state);
  };
  const [selectedBuyCurrency, setSelectedBuyCurrency] = useState("");
  const handleBuyCurrencyChange = (selectedBuyCurrency) => {
    setSelectedBuyCurrency(selectedBuyCurrency);
  };
  const [selectBankDetails, setSelectBankDetails] = useState("");
  const handleBankDetails = (selectBankDetails) => {
    setSelectBankDetails(selectBankDetails);
  };
  const [selectedTransactionOption, setSelectedTransactionOption] =
    useState("");
  const handleTransactionOption = (selectedBuyCurrency) => {
    setSelectedTransactionOption(selectedTransactionOption);
  };

  const [selectedReceiveCurrency, setSelectedReceiveCurrency] = useState('');
  const [selectedSellCurrency, setSelectedSellCurrency] = useState('');

  const [selectedFiatCurrency, setSelectedFiatCurrency] = useState('');
  const [selectedFiatSellCurrency, setSelectedFiatSellCurrency] = useState('');


  const handleReceiveCurrencyChange = (selectedReceiveCurrency) => {
    setSelectedReceiveCurrency(selectedReceiveCurrency);
    dispatch(saveBuyReceiveData(selectedReceiveCurrency));
  };

  const handleSellCurrencyChange = (selectedSellCurrency) => {
    setSelectedSellCurrency(selectedSellCurrency);
    dispatch(saveSellReceiveData(selectedSellCurrency));
  }

  const handleFiatCurrencyChange = (selectedReceiveCurrency) => {
    setSelectedFiatCurrency(selectedReceiveCurrency);
    dispatch(saveBuyFiatData(selectedReceiveCurrency));
  };

  const handleFiatSellCurrencyChange = (selectedSellCurrency) => {
    setSelectedFiatSellCurrency(selectedSellCurrency);
    dispatch(saveSellFiatData(selectedReceiveCurrency));
  }

  useEffect(() => {
    if (selectedReceiveCurrency === '' && platformCurrencies.currecies) {
      setSelectedReceiveCurrency({
        label: platformCurrencies?.currecies[0].currency,
        value: platformCurrencies?.currecies[0].blockchain,
        token: platformCurrencies?.currecies[0].currency,
        code: platformCurrencies?.currecies[0].tokenType,
        source: platformCurrencies?.currecies[0].source,
      });
    }
    if (selectedSellCurrency === '' && platformCurrencies.sellCurrecies) {
      setSelectedSellCurrency({
        label: platformCurrencies?.sellCurrecies[0].currency,
        value: platformCurrencies?.sellCurrecies[0].blockchain,
        token: platformCurrencies?.sellCurrecies[0].currency,
        code: platformCurrencies?.sellCurrecies[0].tokenType,
        source: platformCurrencies?.sellCurrecies[0].source,
      });
    }
    if (selectedSellCurrency === '' && platformCurrencies.buyFiat) {
      const buyFiat = platformCurrencies.buyFiat.filter(currency => currency.default);
      setSelectedFiatCurrency({
        label: buyFiat[0].currency,
        value: buyFiat[0].currency,
        icon: buyFiat[0].icon,
      });
      dispatch(saveBuyFiatData({
        label: buyFiat[0].currency,
        value: buyFiat[0].currency,
        icon: buyFiat[0].icon,
      }));
    }
    if (selectedSellCurrency === '' && platformCurrencies.sellFiat) {
      const sellFiat = platformCurrencies.sellFiat.filter(currency => currency.default);
      setSelectedFiatSellCurrency({
        label: sellFiat[0].currency,
        value: sellFiat[0].currency,
        icon: sellFiat[0].icon,
      });
      dispatch(saveSellFiatData({
        label: sellFiat[0].currency,
        value: sellFiat[0].currency,
        icon: sellFiat[0].icon,
      }));
    }
  }, [platformCurrencies]);


  const [isOpenModal, setIsOpenModal] = useState(false);
  const onOpenModal = () => {
    setIsOpenModal(true);
    if (walletInfo) {
      setIsReviewPurchase(true);
    }
  };
  const onCloseModal = () => {
    setIsCancelPurchase(true);
  };
  const onModalClose = () => {
    setIsOpenModal(false);
  };
  const onCancelPurchaseCancel = () => {
    setIsCancelPurchase(false);
  };
  const [isCancelPurchase, setIsCancelPurchase] = useState(false);
  const onOpenCancelPurchase = () => {
    setIsCancelPurchase(true);
  };
  const onProceedCancelPurchase = () => {
    setIsOpenModal(false);
    setIsCancelPurchase(false);
    setIsPurchaseSuccess(false);
    setIsPurchasePending(false);
    setIsReceivingINR(false);
    setIsPaymentMethod(false);
    setIsKYCSuccess(false);
    setIsKYCFailed(false);
    setIsBuyKYC(false);
    setIsOTP(false);
    setIsLogin(false);
    setIsReviewPurchase(false);
  };
  const [isReviewPurchase, setIsReviewPurchase] = useState(false);
  const onOpenReviewPurchase = () => {
    setIsReviewPurchase(true);
  };

  const reviewLogin = () => {
    isLoggedIn ? setIsReviewPurchase(true) : setIsLogin(true);
  };

  const goToBackWalletDetails = () => {
    setIsReviewPurchase(false);
    setIsOpenModal(true);
  };
  const [isLogin, setIsLogin] = useState(false);
  const onOpenLogin = () => {
    setIsLogin(true);
  };
  const goToBackReviewPurchase = () => {
    setIsLogin(false);
    setIsReviewPurchase(true);
  };
  const [isOTP, setIsOTP] = useState(false);
  const onOpenOTP = () => {
    setIsOTP(true);
  };
  const goToBackLogin = () => {
    setIsOTP(false);
    setIsLogin(true);
  };
  const goToBankDetails = () => {
    setIsSendCrypto(false);
    setIsSelectBank(true);
  };
  const [isBuyKYC, setIsBuyKYC] = useState(false);
  const onOpenBuyKYC = () => {
    setIsBuyKYC(true);
    setIsKYCFailed(false);
  };

  const [openKYCPendingModal, setOpenKYCPendingModal] = useState(false);
  const onOpenKYCPendingModal = () => {
    setIsLogin(false);
    setIsOTP(false);
    setOpenKYCPendingModal(true);
  };
  const onCloseKYCPendingModal = () => {
    setOpenKYCPendingModal(false);
    onCloseModal();
    onCloseSellModal();
    onProceedCancelPurchase();
    onProceedCancelPurchaseSell();
  };

  const [isPaymentMethod, setIsPaymentMethod] = useState(false);
  const onOpenPaymentMethod = () => {
    setIsPaymentMethod(true);
    setIsBuyKYC(false);
  };
  const goToBackOTP = () => {
    setIsPaymentMethod(false);
    setIsOTP(true);
  };
  const [isReceivingINR, setIsReceivingINR] = useState(false);
  const onOpenReceivingINR = () => {
    // setIsReceivingINR(true);
  };
  const [isPurchaseComplete, setIsPurchaseComplete] = useState(false);
  const onOpenPurchaseComplete = () => {
    setIsPurchaseComplete(true);
  };
  const [isPurchasePending, setIsPurchasePending] = useState(false);
  const onOpenPurchasePending = () => {
    setIsOpenModal(false);
  };
  const onClosePurchasePending = () => {
    dispatch(saveWalletAddress(""));
    dispatch(saveUserBank(null));
    setIsOpenModal(false);
    window.location.reload();
  };
  const [isPurchaseSuccess, setIsPurchaseSuccess] = useState(false);
  const onOpenPurchaseSuccess = () => {
    setIsPurchaseSuccess(true);
  };
  const handleClickDone = () => {
    setIsOpenModal(false);
  };
  // sell
  const [isOpenSellModal, setIsOpenSellModal] = useState(false);
  const onOpenSellModal = () => {
    setIsOpenSellModal(true);
  };
  const [isCancelPurchaseSell, setIsCancelPurchaseSell] = useState(false);
  const onOpenCancelPurchaseSell = () => {
    setIsCancelPurchaseSell(true);
  };
  const onCancelPurchaseCancelSell = () => {
    setIsCancelPurchaseSell(false);
    window.location.reload();
  };
  const onCloseSellModal = () => {
    setIsCancelPurchaseSell(true);
    setIsTransactionPending(true);
  };
  const onSellModalClose = () => {
    setIsOpenSellModal(false);
  };
  const onProceedCancelPurchaseSell = () => {
    setIsOpenSellModal(false);
    setIsCancelPurchaseSell(false);
    setIsTransactionSuccess(false);
    setIsTransactionPending(false);
    setIsStepsProcessSell(false);
    setIsSendCrypto(false);
    setIsAddBank(false);
    setIsSelectBank(false);
    setIsKYCSuccess(false);
    setIsKYCFailed(false);
    setIsSellKYC(false);
    setIsOTPSell(false);
  };
  const [isLoginSell, setIsLoginSell] = useState(false);
  const onOpenLoginSell = () => {
    setIsLoginSell(true);
  };
  const [isOTPSell, setIsOTPSell] = useState(false);
  const onOpenOTPSell = () => {
    setIsOTPSell(true);
  };
  const [isSellKYC, setIsSellKYC] = useState(false);
  const onOpenSellKYC = () => {
    setIsSellKYC(true);
  };
  const goToBackLoginSell = () => {
    setIsOTPSell(false);
    setIsLoginSell(true);
  };
  const [isSelectBank, setIsSelectBank] = useState(false);
  const onOpenSelectBank = () => {
    setIsSelectBank(true);
  };
  const goToBackOTPSell = () => {
    setIsSelectBank(false);
    setIsOTPSell(true);
  };
  const [isAddBank, setIsAddBank] = useState(false);
  const onOpenAddBank = () => {
    setIsAddBank(true);
  };
  const goToBackSelectBankSell = () => {
    setIsAddBank(false);
    setIsSelectBank(true);
  };
  const [isSendCrypto, setIsSendCrypto] = useState(false);
  const onOpenSendCrypto = () => {
    setIsSendCrypto(true);
  };
  const goToBackSendSelectBankSell = () => {
    setIsSendCrypto(false);
    setIsSelectBank(true);
  };
  const [isStepsProcessSell, setIsStepsProcessSell] = useState(false);
  const onOpenStepsProcessSell = () => {
    setIsStepsProcessSell(true);
  };
  const [isTransactionFailed, setIsTransactionFailed] = useState(false);
  const onOpenTransactionFailed = () => {
    setIsTransactionFailed(true);
  };
  const [isTransactionPending, setIsTransactionPending] = useState(false);
  const onOpenTransactionPending = () => {
    // setIsTransactionPending(true);
    setIsOpenSellModal(false);
  };
  const onCloseTransactionPending = () => {
    setIsOpenSellModal(false);
    window.location.reload();
  };
  const [isTransactionSuccess, setIsTransactionSuccess] = useState(false);
  const onOpenTransactionSuccess = () => {
    setIsTransactionSuccess(true);
  };
  const handleClickDoneSell = () => {
    setIsOpenSellModal(false);
  };
  const [isKYCSuccess, setIsKYCSuccess] = useState(false);
  const setSellKYCSuccess = () => {
    setIsOpenSellModal(true);
    setIsKYCSuccess(true);
  };
  const onOpenKycSuccess = () => {
    setIsBuyKYC(false);
    setIsSellKYC(false);
    setIsKYCSuccess(true);
  };

  const onCloseKyc = () => {
    setIsKYCSuccess(false);
  };


  const [isKYCFailed, setIsKYCFailed] = useState(false);
  const onOpenKycFailed = () => {
    setIsBuyKYC(false);
    setIsKYCFailed(true);
  };
  const [openDropdown, setOpenDropdown] = useState(false);
  const onClickDropdown = () => {
    setOpenDropdown(true);
  };
  const oncloseDropdown = () => {
    setOpenDropdown(false);
  };
  const VerifyOtpCallback = (verifyOtpResponse) => {
    if (verifyOtpResponse.responseStatus === true) {
      if (isOpenModal === true) {
        setIsOTP(false);
        setIsLogin(false);
        setIsReviewPurchase(true);
      }
      if (isOpenSellModal === true) {
        setIsSelectBank(true);
      }
    }
  };

  const verifyPaymentMethodCallback = (verifyResponse) => {
    if (verifyResponse.responseStatus === true) {
      setIsReceivingINR(true);
    }
  };

  const BuySellCallback = () => {
    if (isOpenModal === true) {
      setIsPaymentMethod(true);
    }
  };

  const [isMenuLine, setIsMenuLine] = useState(false);
  const onOpenMenuLine = () => {
    setIsMenuLine(true);
  };
  const onCloseMenuLine = () => {
    setIsMenuLine(false);
  };
  const [isSearchCryptocurrencies, setIsSearchCryptocurrencies] =
    useState(false);

  const [isSearchFiatcurrencies, setIsSearchFiatcurrencies] = useState(false);

  const onOpenSearchCryptocurrencies = () => {
    setIsSearchCryptocurrencies(true);
  };
  const onCloseSearchCryptocurrencies = () => {
    setIsSearchCryptocurrencies(false);
  };

  const onOpenSearchFiatcurrencies = () => {
    setIsSearchFiatcurrencies(true);
  };
  const onCloseSearchFiatcurrencies = () => {
    setIsSearchFiatcurrencies(false);
  };

  return {
    navTabStatus,
    navTabHandleclick,
    isViewBreakupToggle,
    onViewBreakupToggle,
    selectedTransactionOption,
    handleTransactionOption,
    selectedBuyCurrency,
    handleBuyCurrencyChange,
    setSelectedReceiveCurrency,
    selectedReceiveCurrency,
    setSelectedSellCurrency,
    selectedSellCurrency,
    handleSellCurrencyChange,
    handleReceiveCurrencyChange,
    isOpenModal,
    onOpenModal,
    onCloseModal,
    isReviewPurchase,
    onOpenReviewPurchase,
    goToBackWalletDetails,
    isLogin,
    onOpenLogin,
    goToBackReviewPurchase,
    isOTP,
    onOpenOTP,
    isBuyKYC,
    onOpenBuyKYC,
    goToBackLogin,
    isPaymentMethod,
    onOpenPaymentMethod,
    isCancelPurchase,
    onOpenCancelPurchase,
    onProceedCancelPurchase,
    goToBackOTP,
    isReceivingINR,
    onOpenReceivingINR,
    isPurchaseComplete,
    onOpenPurchaseComplete,
    isPurchasePending,
    onOpenPurchasePending,
    onCancelPurchaseCancel,
    isPurchaseSuccess,
    onOpenPurchaseSuccess,
    handleClickDone,
    isOpenSellModal,
    onOpenSellModal,
    onCloseSellModal,
    isCancelPurchaseSell,
    onOpenCancelPurchaseSell,
    onCloseTransactionPending,
    verifyPaymentMethodCallback,
    isLoginSell,
    onOpenLoginSell,
    onModalClose,
    onSellModalClose,
    isOTPSell,
    onOpenOTPSell,
    isSellKYC,
    onClosePurchasePending,
    onOpenSellKYC,
    goToBackOTPSell,
    goToBackLoginSell,
    isSelectBank,
    onOpenSelectBank,
    isAddBank,
    onOpenAddBank,
    goToBackSelectBankSell,
    isSendCrypto,
    onOpenSendCrypto,
    goToBackSendSelectBankSell,
    isStepsProcessSell,
    onOpenStepsProcessSell,
    handleClickDoneSell,
    onProceedCancelPurchaseSell,
    onCancelPurchaseCancelSell,
    isTransactionFailed,
    onOpenTransactionFailed,
    isTransactionPending,
    onOpenTransactionPending,
    isTransactionSuccess,
    onOpenTransactionSuccess,
    selectBankDetails,
    handleBankDetails,
    isKYCFailed,
    onOpenKycFailed,
    isKYCSuccess,
    onOpenKycSuccess,
    VerifyOtpCallback,
    openDropdown,
    setOpenDropdown,
    onClickDropdown,
    oncloseDropdown,
    isMenuLine,
    onOpenMenuLine,
    onCloseMenuLine,
    isSearchCryptocurrencies,
    onOpenSearchCryptocurrencies,
    onCloseSearchCryptocurrencies,
    onOpenSearchFiatcurrencies,
    onCloseSearchFiatcurrencies,
    BuySellCallback,
    goToBankDetails,
    openKYCPendingModal,
    onCloseKYCPendingModal,
    setSellKYCSuccess,
    onCloseKyc,
    reviewLogin,
    isSearchFiatcurrencies,
    handleFiatCurrencyChange,
    handleFiatSellCurrencyChange,
    selectedFiatSellCurrency,
    selectedFiatCurrency,
  };
};

export default useHome;
