import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// reducers
import { initiateTransactionFetch } from '../../../redux/withdrawal/reducer';
import { saveWithdrawalLoginDaetails } from '../../../redux/withdrawal/persistReducer';
import { clearUserLoginDetails } from "../../../redux/user/persistReducer";
// components
import Section from '../../../components/section';
import Text from '../../../components/text';
import Card from '../../../components/card';
import Flex from '../../../components/flex';
import Button from '../../../components/button';
import MenuLineIcon from '../../../components/svg/menuLineIcon';
import Icon from '../../../components/icon';
import CloseFillIcon from '../../../components/svg/closeFIllIcon';
import NavTextIconList from '../../../components/navTextIconList';
import ArrowDownIcon from '../../../components/svg/arrowDownIcon';
import HistoryFillIcon from '../../../components/svg/historyFillIcon';
import FileUserIcon from '../../../components/svg/fileUserIcon';
import BankFillIcon from '../../../components/svg/bankFillIcon';
import SupportIcon from '../../../components/svg/supportIcon';
import LogoutBoxIcon from '../../../components/svg/logoutBoxIcon';
import Modal from '../../../components/modal';
import CancelPurchase from '../../home/components/buy/cancelPurchase';
import SellForm from '../../home/components/sellForm';
import TransactionPending from '../../home/components/sell/transactionStatus/pending';
import TransactionSuccess from '../../home/components/sell/transactionStatus/successful';
import SellStepsProcess from '../../home/components/sell/stepsProcess';
import SendCrypto from '../../home/components/sell/sendCrypto';
import AddBank from '../../home/components/sell/addBank';
import SelectBank from '../../home/components/sell/selectBank';
import SellKYC from '../../home/components/sell/sellKYC';
import SellLogin from '../../home/components/sell/login';
import SellOTP from '../../home/components/sell/otp';
import BitCoinLoading from '../../../components/loading/bitcoinLoading';
import KYCSuccess from '../../home/components/buy/buyKYC/kycSuccess';
import SearchCryptocurrencies from '../../../components/searchCryptocurrencies';
import ShakeFillIcon from '../../../components/svg/shakeFillIcon';
// hooks
import useHome from '../../home/hooks/home.hook';
// utils
import { APPLICATION_ID } from '../../../utils/constants';

const SellCryptoView = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    onCloseModal,
    isOpenSellModal,
    onOpenSellModal,
    onCloseSellModal,
    isCancelPurchaseSell,
    onSellModalClose,
    isOTPSell,
    onOpenOTPSell,
    isSellKYC,
    goToBackOTPSell,
    goToBackLoginSell,
    isSelectBank,
    onOpenSelectBank,
    isAddBank,
    onOpenAddBank,
    goToBackSelectBankSell,
    isSendCrypto,
    onOpenSendCrypto,
    isStepsProcessSell,
    onOpenStepsProcessSell,
    handleClickDoneSell,
    onProceedCancelPurchaseSell,
    onCancelPurchaseCancelSell,
    isTransactionPending,
    onOpenTransactionPending,
    isTransactionSuccess,
    isKYCSuccess,
    onOpenKycSuccess,
    VerifyOtpCallback,
    isMenuLine,
    onOpenMenuLine,
    onCloseMenuLine,
    isSearchCryptocurrencies,
    onOpenSearchCryptocurrencies,
    onCloseSearchCryptocurrencies,
    BuySellCallback,
    goToBankDetails,
    selectedSellCurrency,
    handleSellCurrencyChange,
  } = useHome();

  const { sellReceiveData } = useSelector(
    (state) => state?.withdrawalPersistReducer
  );

  const { isLoggedIn } = useSelector((state) => state?.userPersistReducer);
  const Loading = useSelector((state) => state.withdrawalReducer.isLoading);

  const { sellData } = useSelector((state) => state?.withdrawalPersistReducer);

  const { phoneNumber } = useSelector(
    (state) => state.userPersistReducer
  );

  const { exchangeRateSell, sellMarkup, applicationId } = useSelector((state) => state?.userReducer);
  
  const sellCallback = (res) => {
    if (res.status === 200) {
      if (res?.data?.verifyUser === true) {
        onOpenSellModal();
        dispatch(clearUserLoginDetails());
      } else {
        onOpenSellModal();
        dispatch(saveWithdrawalLoginDaetails(res.data));
        BuySellCallback(res?.data);
      }
    }
  };

  const onSellInitialization = () => {
    if (isLoggedIn) {
      dispatch(
        initiateTransactionFetch({
          values: {
            amount: sellData?.receiveCurrencyAmount,
            blockchain: sellReceiveData?.value,
            applicationId:applicationId || APPLICATION_ID,
            referenceId: '6e2d2626-55bb-41d5-9892-318e48a45f4b',
            transferType: 'SELL',
            currency: sellReceiveData?.token,
            phoneNumber: phoneNumber?.mobileNumber,
            coinPrice: (exchangeRateSell - (exchangeRateSell * parseFloat(sellMarkup))),
            dexQuote: {}
          },
          callback: sellCallback,
        })
      );
    } else {
      onOpenSellModal();
    }
  };

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const goto = (item) => {
    if (applicationId) {
      openInNewTab(`https://payonramp.com`);
      return;
    }
    isLoggedIn ? navigate(`/${item}`) : navigate('/');
  };

  return (
    <>
      <Section className="section-hero section-new-hero">
        <div className="hero-form-wrapper">
          <div className="hero-form-main">
            <Card className="position-relative">
              <Flex justifyContent="between">
                <Flex>
                  <Button
                    linkBlack
                    type="button"
                    text={<MenuLineIcon />}
                    onClick={onOpenMenuLine}
                  />
                  <Text
                    type="H3"
                    text="PayOnRamp"
                    className="mb-0 ml-3 text-dark-100"
                  />
                </Flex>
              </Flex>
              <Text type="H3" className="mt-2 mb-2" text="Sell" />
              <SellForm
                handleSubmit={onSellInitialization}
                onClick={onOpenSearchCryptocurrencies}
                selectedCurrency={selectedSellCurrency?.token}
                selectedFee={selectedSellCurrency?.fee}
                networkFee={selectedSellCurrency?.networkFee}
                code={selectedSellCurrency?.code}
                source={selectedSellCurrency?.source}
              />
              {isSearchCryptocurrencies && (
                <SearchCryptocurrencies
                  goToBack={onCloseModal}
                  onClose={onCloseSearchCryptocurrencies}
                  selectedCurrency={selectedSellCurrency?.token}
                  onChange={(selectedOption) => {
                    handleSellCurrencyChange(selectedOption);
                    onCloseSearchCryptocurrencies();
                  }}
                />
              )}
              {isMenuLine && (
                <div className="buysell-navmenu-wrp">
                  <Flex justifyContent="end">
                    <Button
                      linkBlack
                      text={<Icon white icon={<CloseFillIcon />} />}
                      onClick={onCloseMenuLine}
                    />
                  </Flex>
                  <NavTextIconList
                    lists={[
                      {
                        id: 1,
                        text: 'Buy',
                        icon: <ArrowDownIcon />,
                        onClick: () => navigate('/buy-crypto'),
                      },
                      {
                        id: 2,
                        text: 'Sell',
                        icon: <ArrowDownIcon up />,
                        onClick: () => navigate('/sell-crypto'),
                      },
                      {
                        id: 3,
                        text: 'History',
                        icon: <HistoryFillIcon />,
                        onClick: () => goto('history'),
                      },
                      {
                        id: 4,
                        text: 'Profile',
                        icon: <FileUserIcon />,
                        onClick: () => goto('profile'),
                      },
                      {
                        id: 5,
                        text: 'Partner',
                        icon: <ShakeFillIcon />,
                        onClick: () => goto('partnership'),
                      },
                      {
                        id: 6,
                        text: 'Bank Accounts',
                        icon: <BankFillIcon />,
                        onClick: () => goto('profile'),
                      },
                      {
                        id: 7,
                        text: 'Support',
                        icon: <SupportIcon />,
                        onClick: () => goto('support'),
                      },
                    ]}
                  />
                </div>
              )}
            </Card>
          </div>
        </div>
      </Section>
      {isOpenSellModal && (
        <Modal
          smallMedium
          minHeight
          onClose={
            isStepsProcessSell
              ? ''
              : isCancelPurchaseSell
              ? ''
              : onCloseSellModal
          }
        >
          {isCancelPurchaseSell ? (
            <CancelPurchase
              onCancel={onCancelPurchaseCancelSell}
              onProceed={onProceedCancelPurchaseSell}
            />
          ) : Loading ? (
            <BitCoinLoading />
          ) : isTransactionSuccess ? (
            <TransactionSuccess handleClick={handleClickDoneSell} />
          ) : isTransactionPending ? (
            <TransactionPending handleClick={onProceedCancelPurchaseSell} />
          ) : isStepsProcessSell ? (
            <SellStepsProcess handleClick={onOpenTransactionPending} />
          ) : isSendCrypto ? (
            <SendCrypto
              handleSubmit={onOpenStepsProcessSell}
              goToBack={goToBankDetails}
              networkFee={selectedSellCurrency?.networkFee}
              code={selectedSellCurrency?.code}
            />
          ) : Loading ? (
            <BitCoinLoading />
          ) : isAddBank ? (
            <AddBank
              handleSubmit={onOpenSendCrypto}
              goToBack={goToBackSelectBankSell}
            />
          ) : isSelectBank ? (
            <SelectBank
              handleSubmit={onOpenSendCrypto}
              handleAddBank={onOpenAddBank}
              goToBack={goToBackOTPSell}
            />
          ) : isKYCSuccess ? (
            <KYCSuccess onClick={onOpenSelectBank} />
          ) : isSellKYC ? (
            <SellKYC
              handleSubmit={onOpenKycSuccess}
              handleClose={onSellModalClose}
            />
          ) : isOTPSell ? (
            <SellOTP
              goToBack={goToBackLoginSell}
              VerifyOtpCallback={VerifyOtpCallback}
            />
          ) : (
            <SellLogin handleSubmit={onOpenOTPSell} />
          )}
        </Modal>
      )}
    </>
  );
};

export default SellCryptoView;
