import { createSlice } from "@reduxjs/toolkit";
// import { depositPersistReducerType } from "../../utils/types";

const depositPersistReducerState = {
  isVerified: false,
  transactionDetails: null,
  accessToken: null,
  refreshToken: null,
  userInfo: false,
  kycVerified: false,
  applicationId: null,
  fiatCurrencyOptions: null,
  sdkDetails: null,
  rules: null,
  allowedCurrencies: null,
  otpReferenceDetails: null,
  paymentWindow: null,
  buyData : null,
  walletAddress: null,
  buyReceiveData:null,
  buyNetworkFee: null,
  buyFiatData: null,
};

export const userSlice = createSlice({
  name: "depositPersistReducer",
  initialState: depositPersistReducerState,
  reducers: {
    // login successful
    saveLoginDaetails: (state, action) => {
      state.isVerified = true;
      state.accessToken = action.payload.accessToken ? action.payload.accessToken : action.payload.accessAuthToken;
      state.refreshToken = action.payload.refreshToken ? action.payload.refreshToken : action.payload.refreshAuthToken;
      state.userInfo = action.payload?.kycApproved;
      state.kycVerified = action.payload?.kycApproved;
    },

    clearLoginDaetails: (state) => {
      state.isVerified = false;
      state.accessToken = null;
      state.refreshToken = null;
      state.userInfo = false;
      state.kycVerified = false;
      state.transactionDetails = null;
    },

    // refresh token successful
    saveTokenDetails: (state, action) => {
      state.accessToken = action.payload.accessToken ? action.payload.accessToken : action.payload.accessAuthToken;
      state.refreshToken = action.payload.refreshToken ? action.payload.refreshToken : action.payload.refreshAuthToken;
    },

    upadteTransactionDetails: (state, action) => {
      state.transactionDetails = action.payload;
    },

    updateUserInfoStatus: (state, action) => {
      state.userInfo = action.payload;
    },

    updateKYCStatus: (state, action) => {
      state.kycVerified = action.payload;
    },

    updateFiatCurrencydetails: (state, action) => {
      state.fiatCurrencyOptions = action.payload;
    },

    updateApllicationId: (state, action) => {
      state.applicationId = action.payload;
    },

    updateSDKDetails: (state, action) => {
      state.sdkDetails = action.payload;
    },

    updateRules: (state, action) => {
      state.rules = action.payload;
    },

    updatePaymentWindow: (state, action) => {
      state.paymentWindow = action.payload;
    },
    saveBuyData : (state, action) => {
      state.buyData = action.payload;
    },
    saveWalletAddress : (state, action) => {
      state.walletAddress = action.payload;
    },
    saveBuyReceiveData : (state, action) => {
      state.buyReceiveData = action.payload;
    },
    saveBuyFiatData : (state, action) => {
      state.buyFiatData = action.payload;
    },
    saveNetworkFee : (state, action) => {
       state.buyNetworkFee = action.payload;
    }
  },
});

export const {
  saveLoginDaetails,
  clearLoginDaetails,
  saveTokenDetails,
  upadteTransactionDetails,
  updateUserInfoStatus,
  updateKYCStatus,
  updateFiatCurrencydetails,
  updateApllicationId,
  updateSDKDetails,
  updateRules,
  updatePaymentWindow,
  saveBuyData,
  saveWalletAddress,
  saveBuyReceiveData,
  saveBuyFiatData,
  saveNetworkFee,
} = userSlice.actions;
export default userSlice.reducer;
