import React, { useEffect, useState } from 'react';
import SendGif from '../../../../../assets/img/gif/send.gif';
import IndianRupeeGif from '../../../../../assets/img/gif/indian-rupee.gif';
import BitcoinWalletGif from '../../../../../assets/img/gif/bitcoin-wallet.gif';
import Flex from '../../../../../components/flex';
import IconTextCard from '../../../../../components/iconTextCard';
import Stepper from '../../../../../components/stepper';
import FadeInOut from '../../../../../components/fadeInOut';
import {
  verifyTransactionFetch,
  updateTransactionData,
} from '../../../../../redux/deposit/reducer';
import { useDispatch, useSelector } from 'react-redux';
import PurchaseComplete from '../purchaseStatus/success';
import PurchaseFailed from '../purchaseStatus/failed';
import PurchasePending from '../purchaseStatus/pending';

const steps = [
  {
    id: '1',
    text: 'Receiving Amount',
    textTitle: '10042',
  },
  {
    id: '2',
    text: 'Converting',
  },
  {
    id: '3',
    text: 'Sending Crypto',
  },
];
const ReceivingINR = (props) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [complete, setComplete] = useState(false);
  const { transactionDetails, cryptoTransferRequest } = useSelector(
    (state) => state.depositReducer
  );

  const dispatch = useDispatch();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentStep((t) => {
        if (t >= 4) clearInterval(intervalId);
        return t < 4 ? t + 1 : t;
      });
    }, 9000);
    return () => clearInterval(intervalId);
  }, []);

  let timerId = null;

  const verifyTransaction = (timeout) => {
    if (timeout <= 0 || cryptoTransferRequest !== 'PENDING') {
      if (timerId) {
        clearInterval(timerId);
        timerId = null;
      }
      if (cryptoTransferRequest === 'SUCCESS') {
        <PurchaseComplete {...props} />;
      } else if (cryptoTransferRequest === 'CANCELLED') {
        <PurchaseFailed {...props} />;
      } else {
        dispatch(updateTransactionData(transactionDetails));
      }
      return;
    }
    dispatch(verifyTransactionFetch());
  };

  useEffect(() => {
    const timeoutDuration = 60 * 1000; // 1 minute in milliseconds
    let callsCount = 0;
    let timerId;

    const timeoutId = setTimeout(() => {
      if (cryptoTransferRequest !== 'PENDING') {
        const time = new Date().getTime();
        const refreshTime = new Date(
          transactionDetails?.transactionTime
        ).getTime();
        const timeout = 60 * 1000 * 1 - time + refreshTime;
        verifyTransaction(timeout);
      } else {
        dispatch(verifyTransactionFetch());

        timerId = setInterval(() => {
          if (callsCount * 15 * 1000 >= timeoutDuration) {
            // Stop the interval after 1 minute
            clearInterval(timerId);
          } else {
            const time = new Date().getTime();
            const refreshTime = new Date(
              transactionDetails?.transactionTime
            ).getTime();
            const timeout = 60 * 1000 * 1.5 - time + refreshTime;

            verifyTransaction(timeout);
            callsCount++;
          }
        }, 15 * 1000);
      }

      // Cleanup function to clear the interval when the component unmounts
      return () => {
        clearTimeout(timeoutId);
        clearInterval(timerId);
      };
    }, [cryptoTransferRequest, transactionDetails]);
  });

  return (
    <>
      {currentStep == '4' ? (
        <FadeInOut className="h-100" show={currentStep == '4'}>
          {cryptoTransferRequest === 'SUCCESS' && (
            <PurchaseComplete {...props} />
          )}
          {cryptoTransferRequest === 'CANCELLED' && (
            <PurchaseFailed {...props} />
          )}
          {cryptoTransferRequest === 'PENDING' && (
            <PurchasePending {...props} />
          )}
        </FadeInOut>
      ) : (
        <Flex flexColumn justifyContent="between" className="p-4 h-100">
          <Flex flexColumn alignItems="center">
            {currentStep == '1' && (
              <FadeInOut show={currentStep == '1'}>
                <IconTextCard icon={SendGif} text="Receiving Amount to our bank" />
              </FadeInOut>
            )}
            {currentStep == '2' && (
              <FadeInOut show={currentStep == '2'}>
                <IconTextCard
                  icon={IndianRupeeGif}
                  text="Converting CAD to Crypto"
                />
              </FadeInOut>
            )}
            {currentStep == '3' && (
              <FadeInOut show={currentStep == '3'}>
                <IconTextCard
                  icon={BitcoinWalletGif}
                  text="Sending CRYPTO to your wallet"
                />
              </FadeInOut>
            )}
          </Flex>
          <Flex flexColumn>
            <Stepper
              currentStep={currentStep}
              complete={complete}
              steps={steps}
            />
          </Flex>
        </Flex>
      )}
    </>
  );
};

export default ReceivingINR;
