import React, { useState } from "react";
import DownArrowIcon from "../svg/dropdownIcon";
import Text from "../text";
import classes from "../../assets/css/Required.module.css";

const InputOption = ({
  text,
  list,
  id,
  label,
  placeholder,
  onChange,
  errormessage,
  value,
  mandatory,
}) => {
  const [isOpenDropdown, setIsOpenDropdown] = useState(false);
  const handleClick = () => {
    setIsOpenDropdown((state) => !state);
  };
  return (
    <div className={`form-field form-control-hide-increment`}>
      <Text
        type="LABEL"
        text={label}
        className={`form-label ${mandatory ? classes.required : ""}`}
      />
      <div
        className="input-option-wrap align-items-center justify-content-between"
        onMouseEnter={() => setIsOpenDropdown(true)}
        onMouseLeave={() => setIsOpenDropdown(false)}
        onClick={handleClick}
      >
        <Text type="LABEL" text={value?.label || placeholder} />
        <DownArrowIcon opacity="1" up={isOpenDropdown} width={10} height={6} />
        {isOpenDropdown && (
          <div className="dropdown-menu">
            <div className="dropdown-main">
              {list?.map((option) => (
                <div
                  key={option.value}
                  className={`dropdown-item ${
                    option?.value !== value?.value ? "active" : ""
                  } p-2`}
                  onClick={() => {
                    onChange(option);
                  }}
                >
                  {option.label}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      {errormessage && (
        <div className="message-error">
          <small>{errormessage}</small>
        </div>
      )}
    </div>
  );
};

export default InputOption;
