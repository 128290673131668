export const ROUTE = {
  login: "/login",
  entercode: "/entercode",
  forgotpassword: "/forgotpassword",
  changepassword: "/changepassword",
  signup: "/register",
  home: "/home",
  createCollection: "/auth/profile/createcollection",
  profile: "/auth/profile/personalinformation",
};

export const TYPE = {
  DISABLE: "DISABLE",
  ENABLE: "ENABLE",
  EMAIL: "EMAIL",
  DEVICE_LOGIN_TYPE: 0,
  DEVICE_SIGNUP_TYPE: 1,
};

export const STATUS_CODE = {
  successful: 200,
  badRequest: 400,
  unAuthorized: 401,
  forbidden: 403,
  notFound: 404,
};

// export const FORMIK_REGEX = {
// PASSWORD_REGEX:
//   /^.*(?=.{6})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
// ALPHA_REGEX: /^[a-zA-Z][a-zA-Z\\s]+$/,
// ALPHA_NUMERIC_REGEX: /^[a-zA-Z0-9]+$/,
// NUMERIC_REGEX: /^(\+\d{1,3}[- ]?)?\d{10}$/,
// EMAIL_REGEX: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
// POSTAL_REGEX: /^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$/,
export const FORMIK_REGEX = {
  ALPHA_REGEX: /^[a-zA-Z ]*$/,
  ALPHA_NUMERIC_REGEX: /^[a-zA-Z0-9]+$/,
  PHONE_NUMBER_REGEX: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  EMAIL_REGEX: /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/,
  ACCOUNT_NUMBER: /^[0-9]{9,18}$/,
  IFSC_CODE: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  UPI_ID: /^[a-zA-Z0-9.-]{2,256}@[a-zA-Z][a-zA-Z]{2,64}$/,
  UPI_UTR: /^[0-9]{12}$/,
  BANK_TRANSFER_UTR: /^[a-zA-Z0-9]{12,22}$/,
  RTGS_UTR: /^[A-Z]{4}R[1-6][0-9]{2}[0-9]{8}[0-9]{8}$/,
  NEFT_UTR: /^[A-Z]{4}[NH][0-9]{2}[0-9]{3}[0-9]{6}$/,
  ETHEREUM_WALLET_ADDRESS: /^0x[a-fA-F0-9]{40}$/,
  BLOCKCHAIN_TX_HASH: /^(0x)?([A-Fa-f0-9]{64})$/,
  NUMERIC_REGEX: /^[0-9]+$/,
};
// };

export const PUBLIC_URL = "https://api.payonramp.io";

export const DEFAULT_ERROR_MESSAGE =
  "We will not be able to process this amount, and we are sorry for the inconvenience caused. Please contact the site administrator for further details.";

export const TOKEN_DETAILS = [
  {
    name: "Ethereum",
    id: "ethereum",
    validator: /^0x[a-fA-F0-9]{40}$/,
    example: "0x05EA2DebD3A32Ea21700F6E0EC371A876EDcE6fA",
  },
];

export const APPLICATION_ID = "7a8d8774-e5fb-4323-911b-55b473b29e2f";

export const USDT_CONTRACT_ADDRESS =
  "0xc56572d99678c11d5E16ebe006e5cF02ea3788c8";

export const DEFAULT_DEPOSIT_RULES =
  "For UPI transfer, please make sure to enter the proper UPI id displayed on the screen. \nFor NEFT/RTGS the transfer time can take upto 24 hours depending on the time of the day.\nPlease make sure you double verify the account number of the beneficiary before transfer.\nABC Money will not be responsibe for transfer that are done to other accounts apart from the accounts which are displayed";

export const DEFAULT_WITHDRAWAL_TERMS =
  'Withdrawal processing time may vary according to payment method. \nWithdrawal requests are considered "pending" until merchant commences their processing.';

export const INFURA_KEY = process.env.NEXT_PUBLIC_INFURA_KEY;
export const ALCHEMY_KEY = process.env.NEXT_PUBLIC_ALCHEMY_KEY;
export const WALLET_CONNECT_PROJECT_ID =
  process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;
export const MAGIC_API_KEY = process.env.NEXT_PUBLIC_MAGIC_API_KEY;

export const TRANSACTIONS_PAGE_LIMIT = 6;
