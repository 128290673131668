import React, { useEffect } from 'react'
import Layout from '../include/layout'
import InteracDeposit from './views/InteracDepostView'

const InteracDepositController = (props) => {
    return (
        <Layout>
            <InteracDeposit />
        </Layout>
    )
}

export default InteracDepositController