import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Flex from '../../../../../components/flex';
import Text from '../../../../../components/text';
import Button from '../../../../../components/button';
import RadioButton from '../../../../../components/radioButton';
import UPIIcon from '../../../../../components/svg/upiIcon';
import VisaIcon from '../../../../../components/svg/visaIcon';
import BankIcon from '../../../../../components/svg/bankIcon';
import Input from '../../../../../components/input';
import Image from '../../../../../components/image';
import UPIAppImg from '../../../../../assets/img/upiapp.svg';
import File from '../../../../../components/input/file';
import QRCodeCard from '../../../../../components/qrCodeCard/index';
import { useDispatch, useSelector } from 'react-redux';
import { FORMIK_REGEX } from '../../../../../utils/constants';
import Checkbox from "../../../../../components/input/checkbox";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  getQRCodeFetch,
  utrTransferFetch,
  paymentOptionsFetch,
  bankDetailsFetch,
} from "../../../../../redux/deposit/reducer";
import CopyToClipboardCard from "../../../../../components/copyToClipboardCard";

const PaymentMethod = (props) => {
  const { qrData, bankDetails, paymentOptions } = useSelector(
    (state) => state.depositReducer
  );

  const { indPay } = useSelector((state) => state?.userReducer);

  const [next, setNext] = useState(false)
  const { buyData, buyReceiveData } = useSelector((state) => state?.depositPersistReducer);
  const formik = useFormik({
    initialValues: {
      paymentMethod: 'INTERAC E-TRANSFER',
      cardNumber: '',
      cardExpiry: '',
      cardCVV: '',
      cardAddress: '',
      cardCountry: '',
      cardPostalCode: '',
      bankAccountType: '',
      bankID: '',
      bankIFSCCode: '',
      bankName: '',
      bankAccountNumber: '',
      bankReceiverName: '',
      bankUTR: '',
      upiUTRNumber: '',
      upiID: qrData?.upiId,
      upiUpload: '',
      interacEmail: "payments@payonramp.com",
      interacMemo: "",
      iCertify1: false,
      iCertify2:false,
      iCertify3:false,
    },
    validationSchema: Yup.object({
      iCertify1: Yup.boolean().oneOf([true], "Please certify"),
      iCertify2: Yup.boolean().oneOf([true], "Please certify"),
      iCertify3: Yup.boolean().oneOf([true], "Please certify"),
      paymentMethod: Yup.string().required("Please enter a payment"),
      upiUTRNumber: Yup.string()
        .required("Please enter a UTR Number")
        .matches(FORMIK_REGEX.UPI_UTR, "Please enter a valid UTR"),
      upiID: Yup.string(),
      upiUpload: Yup.string(),
      cardNumber: Yup.string(),
      cardExpiry: Yup.string(),
      cardCVV: Yup.string(),
      cardAddress: Yup.string(),
      cardCountry: Yup.string(),
      bankAccountType: Yup.string(),
      bankID: Yup.string(),
      bankIFSCCode: Yup.string().matches(
        FORMIK_REGEX.IFSC_CODE,
        "Please enter a valid IFSC code"
      ),
      bankName: Yup.string(),
      bankAccountNumber: Yup.string().matches(
        FORMIK_REGEX.ACCOUNT_NUMBER,
        "Please enter a valid account number"
      ),
      bankReceiverName: Yup.string(),
      bankUTR: Yup.string()
        .required("Please enter a UTR Number")
        .matches(FORMIK_REGEX.BANK_TRANSFER_UTR, "Please enter a valid UTR"),
      interacEmail: Yup.string()
        .required("Please Enter the Interac e-Transfer Email"),
      interacMemo: Yup.string()
        .required("Please Enter the Interac e-Transfer Memo")
    }),
    onSubmit: (values) => {
      bankTransferHandler(values);
      props.handleSubmit();
    },
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(paymentOptionsFetch({ currency: buyData?.buyCurrencySelect }));
  }, []);

  const bankTransferHandler = (values) => {
    if (
      (values.paymentMethod == "Bank Transfer" && values.bankUTR) ||
      (values.paymentMethod !== "Bank Transfer" &&
        values.upiUTRNumbe &&
        !indPay)
    ) {
      const formData = new FormData();
      formData.append("files", values.proofOfTransfer);

      formData.append(
        "data",
        JSON.stringify({
          utrNumber: values.bankUTR,
          bankId: qrData?.bankId,
          transferType: "BANK",
        })
      );
      dispatch(
        utrTransferFetch({
          formData,
          callBack: props.verifyPaymentMethodCallback,
        })
      );
    }
  };

  const interacETransfer = (values) => {
    const formData = new FormData();
    formData.append("files", values.proofOfTransfer);

    formData.append(
      "data",
      JSON.stringify({
        utrNumber: values.interacMemo,
        bankId: qrData?.bankId,
        transferType: "INTERAC_E_TRANSFER",
      })
    );
    dispatch(
      utrTransferFetch({
        formData,
        callBack: props.verifyPaymentMethodCallback,
      })
    );
  };

  const qrPayRequestHandler = (values) => {
    if (!values.upiUTRNumber && !indPay) return;
    const formData = new FormData();
    formData.append("files", values.upiUpload);

    formData.append(
      "data",
      JSON.stringify({
        utrNumber: indPay ? "" : values.upiUTRNumber,
        bankId: qrData?.bankId,
        transferType: "UPI",
      })
    );
    dispatch(
      utrTransferFetch({
        formData,
        callBack: props.verifyPaymentMethodCallback,
      })
    );
  };

  return (
    <div className="payment-method">
      <form
        onSubmit={formik.handleSubmit}
        className="height-100"
      >
        <Flex flexColumn justifyContent="between" className="p-4 height-100">
          <Flex flexColumn>
            <Text type="H3" text="Buy Crypto" className="text-center mb-30" />
            <Text type="LEGEND" text="Payment" />
            <Flex flexColumn>
              <div className="row row-mrl-6 mb-4">
                {paymentOptions?.UPI === true &&(
                  <div className="col-4 col-md-4">
                    <RadioButton
                      value="UPI"
                      name="paymentMethod"
                      onChange={formik.handleChange}
                      isChecked={formik.values.paymentMethod === "UPI"}
                      icon={<UPIIcon />}
                    />
                  </div>
                )}
                {paymentOptions?.BANK_TRANSFER === true &&(
                  <div className="col-4 col-md-4">
                    <RadioButton
                      value="Bank Transfer"
                      name="paymentMethod"
                      onChange={formik.handleChange}
                      isChecked={
                        formik.values.paymentMethod === "Bank Transfer"
                      }
                      icon={<BankIcon />}
                    />
                  </div>
                )}
                {(  
                  <div className="col-4 col-md-4">
                    <RadioButton
                      value="INTERAC E-TRANSFER"
                      name="paymentMethod"
                      onChange={formik.handleChange}
                      isChecked={
                        formik.values.paymentMethod === "INTERAC E-TRANSFER"
                      }
                      icon={<BankIcon />}
                    />
                  </div>
                )}
                {paymentOptions?.INTERNET_BANKING === true && buyReceiveData?.token !== 'CTO' &&(
                  <div className="col-4 col-md-4">
                    <RadioButton
                      value="Credit/Debit"
                      name="paymentMethod"
                      onChange={formik.handleChange}
                      isChecked={formik.values.paymentMethod === "Credit/Debit"}
                      icon={<VisaIcon />}
                    />
                  </div>
                )}
              </div>
              {formik.values.paymentMethod === "UPI" &&
                paymentOptions?.UPI === true && buyReceiveData?.token !== 'CTO' && (
                  <Flex flexColumn>
                    <Flex alignItems="center" className="mb-4">
                      <Flex flexColumn className="pr-3">
                        <Text
                          type="H5"
                          className="font-size-14 font-weight-400 mb-12"
                          text="Scan QR"
                        />
                        <QRCodeCard qrImage={qrData?.UPIQR} />
                      </Flex>
                      <Flex flexColumn className="pl-3 hide-on-web">
                        <Text
                          type="H5"
                          className="font-size-14 font-weight-400 mb-64"
                          text="Click here to open supported UPI App"
                        />
                        <a
                          rel="canonical"
                          target="_blank"
                          href={qrData?.intent}
                        >
                          <UPIIcon />
                        </a>

                        <Text type="H3" className="mt-1 font-weight-700" />
                      </Flex>
                    </Flex>
                    <div className="row row-mrl-6">
                      <div className="col-12">
                        <div className="form-box">
                          <Input
                            label="UPI ID (PhonePe, GPay, Paytm, BHIM UPI)*"
                            type="text"
                            name="upiID"
                            placeholder=""
                            value={qrData?.upiId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            readonly={true}
                            errormessage={
                              formik.touched.upiID && formik.errors.upiID
                                ? formik.errors.upiID
                                : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                    {!indPay && (
                      <div>
                        <div className="col-12">
                          <div className="form-box">
                            <Input
                              label="Enter your UTR number *"
                              type="text"
                              name="upiUTRNumber"
                              placeholder=""
                              value={formik.values.upiUTRNumber}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              mandatory
                              errormessage={
                                formik.touched.upiUTRNumber &&
                                formik.errors.upiUTRNumber
                                  ? formik.errors.upiUTRNumber
                                  : null
                              }
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-box">
                            <File
                              label="Upload transaction screenshot"
                              type="file"
                              name="upiUpload"
                              placeholder="Enter Account Number"
                              value={formik.values.upiUpload}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              mandatory
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <Flex flexColumn className="mt-3">
                      <Button
                        type="submit"
                        text="I have made the payment"
                        onClick={() => {
                          qrPayRequestHandler(formik?.values);
                        }}
                      />
                    </Flex>
                  </Flex>
                )}
              {formik.values.paymentMethod === "Credit/Debit" &&
                paymentOptions?.INTERNET_BANKING === true && (
                  <Flex flexColumn>
                    <div className="row row-mrl-6">
                      <div className="col-12">
                        <div className="form-box">
                          <Input
                            label="Card number"
                            type="text"
                            name="cardNumber"
                            placeholder=""
                            value={formik.values.cardNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={
                              formik.touched.cardNumber &&
                              formik.errors.cardNumber
                                ? formik.errors.cardNumber
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-box">
                          <Input
                            label="Expiry"
                            type="text"
                            name="cardExpiry"
                            placeholder=""
                            value={formik.values.cardExpiry}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={
                              formik.touched.cardExpiry &&
                              formik.errors.cardExpiry
                                ? formik.errors.cardExpiry
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-box">
                          <Input
                            label="CVV"
                            type="text"
                            name="cardCVV"
                            placeholder=""
                            value={formik.values.cardCVV}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={
                              formik.touched.cardCVV && formik.errors.cardCVV
                                ? formik.errors.cardCVV
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-box">
                          <Input
                            label="Address"
                            type="text"
                            name="cardAddress"
                            placeholder=""
                            value={formik.values.cardAddress}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={
                              formik.touched.cardAddress &&
                              formik.errors.cardAddress
                                ? formik.errors.cardAddress
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-box">
                          <Input
                            label="Country"
                            type="text"
                            name="cardCountry"
                            placeholder=""
                            value={formik.values.cardCountry}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={
                              formik.touched.cardCountry &&
                              formik.errors.cardCountry
                                ? formik.errors.cardCountry
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-box">
                          <Input
                            label="Postal Code"
                            type="text"
                            name="cardPostalCode"
                            placeholder=""
                            value={formik.values.cardPostalCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={
                              formik.touched.cardPostalCode &&
                              formik.errors.cardPostalCode
                                ? formik.errors.cardPostalCode
                                : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <Flex flexColumn className="mt-3">
                      <Button type="submit" text="I have made the payment" />
                    </Flex>
                  </Flex>
                )}
              {formik.values.paymentMethod === "Bank Transfer" &&
                paymentOptions?.BANK_TRANSFER === true && (
                  <Flex flexColumn>
                    <div className="row row-mrl-6">
                      <div className="col-6">
                        <div className="form-box">
                          <Input
                            label="Account Number"
                            type="text"
                            name="bankAccountNumber"
                            placeholder=""
                            value={bankDetails?.accountNumber}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            readonly={true}
                            errormessage={
                              formik.touched.bankAccountNumber &&
                              formik.errors.bankAccountNumber
                                ? formik.errors.bankAccountNumber
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-box">
                          <Input
                            label="Bank ID"
                            type="text"
                            name="bankID"
                            placeholder=""
                            value={bankDetails?.BankId}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            readonly={true}
                            errormessage={
                              formik.touched.bankID && formik.errors.bankID
                                ? formik.errors.bankID
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-box">
                          <Input
                            label="IFSC Code"
                            type="text"
                            name="bankIFSCCode"
                            placeholder=""
                            value={bankDetails?.IFSC}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            readonly={true}
                            errormessage={
                              formik.touched.bankIFSCCode &&
                              formik.errors.bankIFSCCode
                                ? formik.errors.bankIFSCCode
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-box">
                          <Input
                            label="Bank Name"
                            type="text"
                            name="bankName"
                            placeholder=""
                            value={bankDetails?.bankName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            readonly={true}
                            errormessage={
                              formik.touched.bankName && formik.errors.bankName
                                ? formik.errors.bankName
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-box">
                          <Input
                            label="Receiver Name"
                            type="text"
                            name="bankReceiverName"
                            placeholder=""
                            value={bankDetails?.accountHolderName}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={
                              formik.touched.bankReceiverName &&
                              formik.errors.bankReceiverName
                                ? formik.errors.bankReceiverName
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-box">
                          <Input
                            label="Account Type"
                            type="text"
                            name="bankAccountType"
                            placeholder=""
                            value={`Current`}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={
                              formik.touched.bankAccountType &&
                              formik.errors.bankAccountType
                                ? formik.errors.bankAccountType
                                : null
                            }
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-box">
                          <Input
                            label="Enter UTR/Transaction ID*"
                            type="text"
                            name="bankUTR"
                            placeholder=""
                            value={formik.values.bankUTR}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={
                              formik.touched.bankUTR && formik.errors.bankUTR
                                ? formik.errors.bankUTR
                                : null
                            }
                          />
                        </div>
                      </div>
                    </div>
                    <Flex flexColumn className="mt-3">
                      <Button
                        type="submit"
                        text="I have made the payment"
                        onClick={() => {
                          bankTransferHandler(formik?.values);
                        }}
                      />
                    </Flex>
                  </Flex>
                )}
              {!next && (
                  <Flex flexColumn>
                    <div className="row row-mrl-6">
                      <div className="col-12">
                        <div className="form-box">
                          <Input
                            label="Send the Interac e-Transfer to :"
                            type="text"
                            name="interacEmail"
                            placeholder=""
                            value={formik.values.interacEmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={
                              formik.touched.interacEmail &&
                              formik.errors.interacEmail
                                ? formik.errors.interacEmail
                                : null
                            }
                          />
                        </div>
                        <Checkbox
                          value={formik.values.iCertify1}
                          label={
                            <Flex flexWrap>
                              <Text
                                type="SPAN"
                                text="The name on the bank account I am sending an e-Transfer matches the name on my PayOnRamp Account."
                                className="d-inline-flex"
                              />
                            </Flex>
                          }
                          id="iCertify1"
                          isChecked={formik.values.iCertify1}
                          onChange={formik.handleChange}
                          errormessage={
                            formik.touched.iCertify1 && formik.errors.iCertify1
                              ? formik.errors.iCertify1
                              : null
                          }
                        />
                        <Checkbox
                          value={formik.values.iCertify2}
                          label={
                            <Flex flexWrap>
                              <Text
                                type="SPAN"
                                text="I understood that if I send an e-Transfer from a bank account that is not under my name, my PayOnRamp account will be banned."
                                className="d-inline-flex"
                              />
                            </Flex>
                          }
                          id="iCertify2"
                          isChecked={formik.values.iCertify2}
                          onChange={formik.handleChange}
                          errormessage={
                            formik.touched.iCertify2 && formik.errors.iCertify2
                              ? formik.errors.iCertify2
                              : null
                          }
                        />
                      </div>
                    </div>
                    <Flex flexColumn className="mt-3">
                      <Button
                        type="submit"
                        text="Next"
                        onClick={() => {
                          const { interacEmail, iCertify1, iCertify2 } =
                            formik.values;
                          if (interacEmail !== "" && iCertify1 && iCertify2) {
                            setNext(true);
                          }
                        }}
                      />
                    </Flex>
                  </Flex>
                )}
              {next && (
                  <Flex flexColumn>
                    <div className="row row-mrl-6">
                      <div className="col-12">
                        <div className="form-box">
                          <h6>
                            Login to your online bank and initaite an e-Transfer.
                          </h6>
                          <Input
                            label="Enter the Reference Transfer Number :"
                            type="text"
                            name="interacMemo"
                            placeholder=""
                            value={formik.values.interacMemo}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            mandatory
                            errormessage={
                              formik.touched.interacMemo &&
                              formik.errors.interacMemo
                                ? formik.errors.interacMemo
                                : null
                            }
                          />
                        </div>
                        <Checkbox
                          value={formik.values.iCertify3}
                          label={
                            <Flex flexWrap>
                              <Text
                                type="SPAN"
                                text={`I understand that failure to enter ${formik.values.interacMemo} as my memo/message is my e-transfer will cause along delay.`}
                                className="d-inline-flex"
                              />
                            </Flex>
                          }
                          id="iCertify3"
                          isChecked={formik.values.iCertify3}
                          onChange={formik.handleChange}
                          errormessage={
                            formik.touched.iCertify3 && formik.errors.iCertify3
                              ? formik.errors.iCertify3
                              : null
                          }
                        />
                      </div>
                    </div>
                    <Flex flexColumn className="mt-3">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          text="Back"
                          onClick={() => {
                            setNext(false);
                          }}
                        />
                        <Button
                          type="submit"
                          text="Submit"
                          onClick={() => {
                            const { interacMemo, iCertify3 } = formik.values;
                            if (interacMemo !== "" && iCertify3) {
                              interacETransfer(formik.values);
                            }
                          }}
                        />
                      </div>
                    </Flex>
                  </Flex>
                )}
            </Flex>
          </Flex>
        </Flex>
      </form>
    </div>
  );
};

export default PaymentMethod;
