// const aes256 = require("aes256");

// const ENCRYPTION_KEY = process.env.NEXT_PUBLIC_ENCRYPTION_KEY;

// //Encrypting text
// export const encrypt = (payload) => {
//   const encryptedText = aes256.encrypt(ENCRYPTION_KEY, payload);
//   return encryptedText;
// };

// // Decrypting text
// export const decrypt = (encryptedString) => {
//   const decryptedString = aes256.decrypt(ENCRYPTION_KEY, encryptedString);

//   return decryptedString.trim();
// };

// truncate number
export const truncate = (number) => {
  if (number < 1) return Math.floor(number * 100000000) / 100000000;
  return Math.floor(number * 10000) / 10000;
}
